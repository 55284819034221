'use client';

import { Address } from '@/components/_common/Address/Address';
import {
  DynamicFooterComponent,
  DynamicFormComponent,
} from '@/components/_common/Form/FormTypes';
import { useTask } from '@/context/TaskContextProvider';

const components: DynamicFormComponent[] = [
  {
    componentId: 'address',
    componentLabel: 'What’s your residential address?',
    componentGuidance:
      'This is where you live and can be different to the property being sold.',
    componentInputs: [
      {
        name: 'line1',
        type: 'text',
        label: 'First line',
        information: 'More information about this input',
        default_value: null,
        placeholder: 'First line...',
        config: {
          required: { value: true, message: 'Required' },
        },
      },
      {
        name: 'line2',
        type: 'text',
        label: 'Second line',
        information: 'More information about this input',
        default_value: null,
        placeholder: 'Second line...',
      },
      {
        name: 'town',
        type: 'text',
        label: 'City',
        information: 'More information about this input',
        default_value: null,
        placeholder: 'City...',
        config: {
          required: { value: true, message: 'Required' },
        },
      },
      {
        name: 'postcode',
        type: 'text',
        label: 'Postcode',
        information: 'More information about this input',
        default_value: null,
        placeholder: 'Postcode...',
        config: {
          required: { value: true, message: 'Required' },
        },
      },
      {
        name: 'uprn',
        type: 'text',
        label: 'UPRN',
        information: 'More information about this input',
        disabled: true,
        default_value: 'UPRNID1234567890',
        placeholder: 'UPRN...',
      },
    ],
  },
];

const footer: DynamicFooterComponent[] = [
  {
    componentId: 'backNext',
    componentInputs: [
      {
        name: 'backNext',
        type: 'backNext',
        label: 'Next',
        rank: 'primary',
        iconRight: 'chevron_right',
      },
    ],
  },
];

type Props = {
  onSubmit: (data: any) => void;
};

function TransactionParticipantAddress({ onSubmit }: Props) {
  const {
    state: { formValues },
    dispatch,
  } = useTask();

  const handleSubmit = (formData: any) => {
    dispatch({ type: 'SET_FORM_VALUES', payload: formData });
    onSubmit(formData);
  };

  return (
    <Address
      components={components}
      footerComponents={footer}
      onSubmit={(formData) => handleSubmit(formData)}
      onBack={() => dispatch({ type: 'BACK' })}
      formFields={components[0].componentInputs.map((input) => input.name)}
      label={components[0].componentLabel}
      guidance={components[0].componentGuidance}
      formValues={formValues}
    />
  );
}

export default TransactionParticipantAddress;
