'use client';

import Splash from '@/components/_common/Splash/Splash';
import { useAuth } from '@/context/AuthContextProvider';
import { useTask } from '@/context/TaskContextProvider';
import SelectTitlesBg from '@/public/select-titles-bg.svg';
import { SELECT_TITLES } from '@/services/mutations';
import { GET_TRANSACTION_TITLES } from '@/services/queries';
import { wait } from '@/utils/helpers';
import { useMutation, useQuery } from '@apollo/client';
import ChevronRight from '@mui/icons-material/ChevronRight';
import LockIcon from '@mui/icons-material/Lock';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import ls from 'localstorage-slim';
import ReviewTitles from './components/ReviewTitles';
import SelectTitles from './components/SelectTitles';

type Props = {
  transactionId: string;
  onComplete?: (data?: any) => void;
};

/**
 * Component: Transaction Select Titles
 */
function TransactionSelectTitles({ transactionId, onComplete }: Props) {
  const { userId } = useAuth();
  const {
    state: { activeStep, isComplete, isSubmitting, formValues, waitDuration },
    dispatch,
  } = useTask();

  const { data, loading, error, refetch } = useQuery(GET_TRANSACTION_TITLES, {
    variables: { transactionId: transactionId },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  });

  const [selectTitles, { error: mutationError }] = useMutation(SELECT_TITLES);

  const titles = data?.registerExtractByTransaction?.titles || [];
  const hasTitles = data?.registerExtractByTransaction?.hasTitles;
  const isTitlesRequested =
    ls.get(`titles-requested-${transactionId}-${userId}`) || null;

  const handleMutation = async () => {
    const selectedTitles = formValues.titleNumbers.map(
      (titleNumber: string) => {
        const matchedTitle = titles.find(
          (title: any) => title.titleNumber === titleNumber
        );
        return {
          titleNumber: titleNumber,
          titleClass: matchedTitle.tenure,
        };
      }
    );

    const input = {
      transactionId: transactionId,
      titles: selectedTitles,
    };

    dispatch({ type: 'SET_IS_SUBMITTING', payload: true });

    selectTitles({
      variables: { input: input },
      onCompleted: async () => {
        ls.set(`titles-requested-${transactionId}-${userId}`, true, {
          ttl: 60,
        });
        await wait(waitDuration);
        refetch();
        dispatch({ type: 'SET_IS_SUBMITTING', payload: false });
        dispatch({ type: 'SET_IS_COMPLETE', payload: true });
      },
    });
  };

  if (isSubmitting)
    return (
      <Splash
        title={`Requesting your titles...`}
        footer={
          <Button
            variant={'contained'}
            disabled={isSubmitting}
            size="large"
            endIcon={isSubmitting ? <LockIcon /> : null}
            sx={{ width: '100%' }}
            onClick={onComplete}
            data-testid="next"
          >
            Next
          </Button>
        }
        isLoading={isSubmitting}
      />
    );

  if (hasTitles === true) {
    return (
      <Splash
        title={'Titles requested!'}
        subtitle={`You’ve completed this task and we’ve requested your titles from HM Land Registry. You’ll find them in your documents shortly.`}
        backgroundImage={SelectTitlesBg}
        footer={
          <Stack gap={1} sx={{ flexDirection: 'row' }}>
            <Button
              variant={'contained'}
              size="large"
              endIcon={<ChevronRight />}
              sx={{ width: '100%', flex: 1 }}
              onClick={onComplete}
            >
              Next
            </Button>
          </Stack>
        }
        isLoading={isSubmitting}
        isSuccess={true}
      />
    );
  }

  if (isComplete || isTitlesRequested)
    return (
      <Splash
        title={'Requesting your titles...'}
        subtitle={`Hold tight we’re getting your titles from HM Land Registry. This task will automatically complete when it’s finished.`}
        backgroundImage={SelectTitlesBg}
        footer={
          <Stack gap={1} sx={{ flexDirection: 'row' }}>
            <Button
              variant={'contained'}
              disabled={isSubmitting}
              size="large"
              endIcon={<ChevronRight />}
              sx={{ width: '100%', flex: 1 }}
              onClick={onComplete}
            >
              Next
            </Button>
          </Stack>
        }
        isLoading={isSubmitting}
        isSuccess={false}
      />
    );

  return (
    <>
      {activeStep === 1 && (
        <SelectTitles titles={titles} hasTitles={hasTitles} loading={loading} />
      )}
      {activeStep === 2 && (
        <ReviewTitles titles={titles} onSubmit={handleMutation} />
      )}
    </>
  );
}

export default TransactionSelectTitles;
