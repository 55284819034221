'use client';

import Splash from '@/components/_common/Splash/Splash';
import { START_TRANSACTION_DOCUMENT } from '@/services/mutations';
import { useMutation } from '@apollo/client';
import Stack from '@mui/material/Stack';
import { useEffect } from 'react';

type Props = {
  transactionId: string;
  documentType?: string;
  onComplete?: (data?: any) => void;
};

const TransactionDocumentSigning = ({
  transactionId,
  documentType = 'TA6ed4',
  onComplete,
}: Props) => {
  const [startTransactionDocument, { data, loading, error }] = useMutation(
    START_TRANSACTION_DOCUMENT
  );

  useEffect(() => {
    if (transactionId) {
      const input = {
        transactionId: transactionId,
        documentType: documentType,
      };

      startTransactionDocument({
        variables: { input: input },
      });
    }
  }, []);

  if (error) throw new Error(error?.message);

  if (loading)
    return <Splash title="Loading..." footer={null} isLoading={true} />;

  return (
    <Stack
      sx={{
        p: { xs: 1, sm: 2, md: 2 },
        height: '100%',
        justifyContent: 'center',
        flexDirection: 'row',
      }}
    >
      {data?.startTransactionDocument?.link && (
        <iframe
          src={data?.startTransactionDocument?.link}
          width="100%"
          height="100%"
          style={{
            maxWidth: '800px',
            border: 'none',
            borderRadius: '8px',
            overflow: 'hidden',
          }}
        ></iframe>
      )}
    </Stack>
  );
};

export default TransactionDocumentSigning;
