'use client';

import { Form } from '@/components/_common/Form/Form';
import {
  DynamicFooterComponent,
  DynamicFormComponent,
} from '@/components/_common/Form/FormTypes';
import { useTask } from '@/context/TaskContextProvider';

function TransactionParticipantName() {
  const {
    state: { formValues },
    dispatch,
  } = useTask();

  const components: DynamicFormComponent[] = [
    {
      componentId: 'name',
      componentLabel: `What’s your legal first name?`,
      componentGuidance:
        'We ask as it’s sometimes different to the name you sign up with and this name will appear on any contracts you sign.',
      componentInputs: [
        {
          name: 'firstName',
          type: 'text',
          label: 'Legal first name',
          default_value: '',
          placeholder: 'e.g. Sherlock',
          iconLeft: 'person',
          config: {
            required: { value: true, message: 'Required' },
          },
        },
      ],
    },
    {
      componentId: 'name',
      componentLabel: `What’s your legal last name?`,
      componentInputs: [
        {
          name: 'lastName',
          type: 'text',
          label: 'Legal last name',
          default_value: '',
          placeholder: 'e.g. Homes',
          iconLeft: 'person',
          config: {
            required: { value: true, message: 'Required' },
          },
        },
      ],
    },
  ];

  const footer: DynamicFooterComponent[] = [
    {
      componentId: 'submit',
      componentInputs: [
        {
          name: 'submit',
          type: 'submit',
          label: 'Next',
          rank: 'primary',
          iconRight: 'chevron_right',
        },
      ],
    },
  ];

  return (
    <>
      <Form
        components={components}
        footerComponents={footer}
        formValues={formValues}
        onSubmit={(formData) => dispatch({ type: 'NEXT', payload: formData })}
        onBack={() => dispatch({ type: 'BACK' })}
      />
    </>
  );
}

export default TransactionParticipantName;
