import Refresh from '@mui/icons-material/Refresh';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';

type Props = {
  seconds: number; //count in seconds
  refetch: () => void;
};

function CircularProgressButton({ seconds, refetch }: Props) {
  const [count, setCount] = useState(seconds);

  useEffect(() => {
    const timer = setInterval(() => {
      setCount((prevCount: any) => prevCount - 1);
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const CircularProgressIcon = () => {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress
          color="primary"
          variant="determinate"
          value={(count / seconds) * 100}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="caption" component="div" color="text.secondary">
            {count}
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <Button
      variant="contained"
      color="primary"
      size="large"
      type="submit"
      sx={{ width: '100%' }}
      endIcon={count >= 0 ? <CircularProgressIcon /> : <Refresh />}
      onClick={() => refetch()}
      disabled={count >= 0}
      data-testid="refresh"
    >
      I&#39;ve done this
    </Button>
  );
}

export default CircularProgressButton;
