'use client';

import { Form } from '@/components/_common/Form/Form';
import {
  DynamicFooterComponent,
  DynamicFormComponent,
} from '@/components/_common/Form/FormTypes';
import { useTask } from '@/context/TaskContextProvider';

function TransactionParticipantSellerCapacity() {
  const {
    state: { formValues },
    dispatch,
  } = useTask();

  const components: DynamicFormComponent[] = [
    {
      componentId: 'capacity',
      componentLabel: 'In what capacity are you selling this property?',
      componentGuidance: null,
      componentInputs: [
        {
          name: 'capacity',
          type: 'radio',
          label: null,
          options: [
            {
              id: 'Legal owner',
              label: 'Legal owner',
            },
            {
              id: 'Personal Representative for a Deceased Owner',
              label: 'Personal Representative for a Deceased Owner',
            },
            {
              id: 'Under Power of Attorney',
              label: 'Under Power of Attorney',
            },
            {
              id: 'Mortgagee in Possession',
              label: 'Mortgagee in Possession',
            },
            {
              id: 'Other',
              label: 'Other',
            },
          ],
          config: {
            required: {
              value: true,
              message: 'Please select choose an option',
            },
          },
        },
      ],
    },
  ];

  const footer: DynamicFooterComponent[] = [
    {
      componentId: 'submit',
      componentInputs: [
        {
          name: 'submit',
          type: 'submit',
          label: 'Next',
          rank: 'primary',
          iconRight: 'chevron_right',
        },
      ],
    },
  ];

  return (
    <>
      <Form
        components={components}
        footerComponents={footer}
        formValues={formValues}
        onSubmit={(formData) => dispatch({ type: 'NEXT', payload: formData })}
      />
    </>
  );
}

export default TransactionParticipantSellerCapacity;
