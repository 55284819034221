'use client';

import Splash from '@/components/_common/Splash/Splash';
import { useTask } from '@/context/TaskContextProvider';
import IdentityBg from '@/public/identity.svg';
import { ADD_INFORMATION_TO_TRANSACTION_PARTICIPANT } from '@/services/mutations';
import { GET_TRANSACTION_PARTICIPANT_INFO } from '@/services/queries';
import { wait } from '@/utils/helpers';
import { useMutation, useQuery } from '@apollo/client';
import LockIcon from '@mui/icons-material/Lock';
import Refresh from '@mui/icons-material/Refresh';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { useEffect, useState } from 'react';
import TransactionParticipantAddress from './components/TransactionParticipantAddress';
import TransactionParticipantCards from './components/TransactionParticipantCards';
import TransactionParticipantDoB from './components/TransactionParticipantDoB';
import TransactionParticipantName from './components/TransactionParticipantName';
import TransactionParticipantSellerCapacity from './components/TransactionParticipantSellerCapacity';

type Props = {
  transactionId: string;
  onComplete?: (data?: any) => void;
};

function TransactionParticipantInfo({ transactionId, onComplete }: Props) {
  const {
    state: { activeStep, isComplete, isSubmitting, formValues, waitDuration },
    dispatch,
  } = useTask();
  const [currentParticipant, setCurrentParticipant] = useState<any>(null);
  const [participantsInfoComplete, setParticipantsInfoComplete] =
    useState<boolean>(false);

  const { data, loading, error, refetch } = useQuery(
    GET_TRANSACTION_PARTICIPANT_INFO,
    {
      variables: { transactionId: transactionId },
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
    }
  );

  const [addInformationToTransactionParticipant, { error: mutationError }] =
    useMutation(ADD_INFORMATION_TO_TRANSACTION_PARTICIPANT);

  const handleMutation = async (formData: any) => {
    const input = {
      transactionId: transactionId,
      participantId: currentParticipant.participantId,
      addressLine1: formValues.line1 || formData.line1,
      addressLine2: formValues.line2 || formData.line2,
      addressTown: formValues.town || formData.town,
      addressPostcode: formValues.postcode || formData.postcode,
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      capacity: formValues.capacity || null,
      dateOfBirth: formValues.dateOfBirth,
    };

    dispatch({ type: 'SET_IS_SUBMITTING', payload: true });

    addInformationToTransactionParticipant({
      variables: { input: input },
      onCompleted: async () => {
        await wait(waitDuration);
        refetch();
        dispatch({ type: 'SET_IS_SUBMITTING', payload: false });
        dispatch({ type: 'SET_IS_COMPLETE', payload: true });
      },
    });
  };

  useEffect(() => {
    if (data) {
      const currentParticipant = data.transaction.participants.find(
        (participant: any) => participant.currentUser
      );

      setCurrentParticipant(currentParticipant);
      setParticipantsInfoComplete(
        data.transaction.participants.every(
          (participant: any) => participant.hasInfo
        )
      );
      dispatch({ type: 'SET_TOTAL_STEPS', payload: 4 });
      dispatch({ type: 'SET_ACTIVE_STEP', payload: 1 });
    }
  }, [data, dispatch]);

  if (error || mutationError)
    throw new Error(error?.message || mutationError?.message);

  if (loading)
    return <Splash title="Loading..." footer={null} isLoading={true} />;

  if (isSubmitting)
    return (
      <Splash
        title={`Saving your personal information...`}
        footer={
          <Button
            variant={'contained'}
            disabled={isSubmitting}
            size="large"
            endIcon={isSubmitting ? <LockIcon /> : null}
            sx={{ width: '100%' }}
            onClick={onComplete}
            data-testid="next"
          >
            Next
          </Button>
        }
        isLoading={isSubmitting}
      />
    );

  if (isComplete || (currentParticipant && currentParticipant?.hasInfo))
    return (
      <Splash
        title={
          participantsInfoComplete
            ? `Everyone’s added their personal information.`
            : `You’ve added your personal information.`
        }
        subtitle={
          participantsInfoComplete
            ? 'This task is complete, there’s nothing left for you to do here.'
            : `Once everyone else has added their personal information we’ll mark this task as complete.`
        }
        content={
          <TransactionParticipantCards
            participants={data.transaction.participants}
          />
        }
        backgroundImage={IdentityBg}
        footer={
          <Stack gap={1} sx={{ flexDirection: 'row' }}>
            <IconButton
              color="secondary"
              size="large"
              sx={{ flex: 0 }}
              onClick={() => refetch()}
              data-testid="refresh"
            >
              <Refresh />
            </IconButton>
            <Button
              variant={'contained'}
              disabled={isSubmitting}
              size="large"
              endIcon={isSubmitting ? <LockIcon /> : null}
              sx={{ width: '100%', flex: 1 }}
              onClick={onComplete}
            >
              Next
            </Button>
          </Stack>
        }
        isLoading={isSubmitting}
        isSuccess={participantsInfoComplete}
      />
    );

  return (
    <>
      {activeStep === 1 && <TransactionParticipantName />}
      {activeStep === 2 && (
        <TransactionParticipantDoB transactionType={data?.transaction.type} />
      )}
      {activeStep === 3 && <TransactionParticipantSellerCapacity />}
      {activeStep === 4 && (
        <TransactionParticipantAddress onSubmit={handleMutation} />
      )}
    </>
  );
}

export default TransactionParticipantInfo;
