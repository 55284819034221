'use client';

import House from '@/public/house.svg';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { capitalize } from '@mui/material/utils';
import Image from 'next/image';
import Link from 'next/link';
import { useEffect, useState } from 'react';

type Props = {
  transaction: any;
};

const PLACEHOLDER_IMAGE_URL = House.src;
const BLUR_IMAGE_URL =
  'data:image/jpg;base64,/9j/4AAQSkZJRgABAQAASABIAAD/2wBDAAIBAQEBAQIBAQECAgICAgQDAgICAgUEBAMEBgUGBgYFBgYGBwkIBgcJBwYGCAsICQoKCgoKBggLDAsKDAkKCgr/2wBDAQICAgICAgUDAwUKBwYHCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgr/wAARCAAKAAoDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAACAUG/8QAKxAAAQMCBAILAQAAAAAAAAAAAQIDBAUGAAcIERIhCRMVIiMyNUFCZHGB/8QAFQEBAQAAAAAAAAAAAAAAAAAABQb/xAAeEQABAgcBAAAAAAAAAAAAAAABAgQAAwUGESFBof/aAAwDAQACEQMRAD8AQuonpRcnoanZ+XeQdAjxG5nU9t1SqOcT69j4KorDCeFQO3yUCQQDtzNula5NCUymRpdz2NdEWpOsIXUY0S2lOtNPlILiELCe+kK3AV7gA4GNq+gRB99R/vLG5R5B+YMa3ZXxKCkziM8ijFuW642tsCR3Oz5H/9k=';

function TransactionCard({ transaction }: Props) {
  const [image, setImage] = useState(
    transaction.imageUrl || PLACEHOLDER_IMAGE_URL
  );

  useEffect(() => {
    if (transaction) setImage(transaction?.imageUrl || PLACEHOLDER_IMAGE_URL);
  }, [transaction]);

  return (
    <Grid item xl={3} lg={4} md={6} sm={12} xs={6}>
      <Link
        href={`/transactions/${transaction.type}s/${transaction.transactionId}/property`}
      >
        <Card className="hover" data-testid={transaction.transactionId}>
          <CardActionArea>
            <CardMedia>
              <Image
                src={image}
                alt={transaction.shortAddress || 'kotini'}
                fill
                objectFit="cover"
                quality={30}
                placeholder="blur"
                blurDataURL={BLUR_IMAGE_URL}
                priority
                onError={() => {
                  setImage(PLACEHOLDER_IMAGE_URL);
                }}
              />
            </CardMedia>
            <CardContent>
              <Typography variant="h6">{transaction.shortAddress}</Typography>
              <Chip label={capitalize(transaction.type)} />
            </CardContent>
          </CardActionArea>
        </Card>
      </Link>
    </Grid>
  );
}

export default TransactionCard;
