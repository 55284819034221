import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';

function CardLoader() {
  return (
    <Grid item xl={3} lg={4} md={6} sm={12} xs={6}>
      <Card elevation={0}>
        <Skeleton
          variant="rounded"
          sx={{
            width: 'calc(100% - 32px)',
            height: 'auto',
            aspectRatio: 1,
            borderRadius: 1,
          }}
        />
        <CardContent>
          <Skeleton variant="text" width={100} height={20} />
          <Skeleton variant="text" width={60} height={16} />
        </CardContent>
      </Card>
    </Grid>
  );
}

export default CardLoader;
