'use client';

import PersonalInfoImage from '@/public/PersonalInfo.svg';
import { GET_USER } from '@/services/queries';
import { useQuery } from '@apollo/client';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import CardMedia from '@mui/material/CardMedia';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

/**
 * Component: Personal Information
 */
export default function PersonalInformation() {
  const { data, loading, error } = useQuery(GET_USER);

  const TableLoader = () => {
    return (
      <TableContainer>
        <Table>
          <TableBody>
            {/* @ts-ignore */}
            <TableRow variant="stack">
              <TableCell data-testid="name-key">
                <Skeleton variant="text" sx={{ width: '60%' }} />
              </TableCell>
              <TableCell align="right">
                <Skeleton variant="text" sx={{ width: '100%' }} />
              </TableCell>
            </TableRow>
            {/* @ts-ignore */}
            <TableRow variant="stack">
              <TableCell data-testid="name-key">
                <Skeleton variant="text" sx={{ width: '60%' }} />
              </TableCell>
              <TableCell align="right">
                <Skeleton variant="text" sx={{ width: '100%' }} />
              </TableCell>
            </TableRow>
            {/* @ts-ignore */}
            <TableRow variant="stack">
              <TableCell data-testid="name-key">
                <Skeleton variant="text" sx={{ width: '60%' }} />
              </TableCell>
              <TableCell align="right">
                <Skeleton variant="text" sx={{ width: '100%' }} />
              </TableCell>
            </TableRow>
            {/* @ts-ignore */}
            <TableRow variant="stack">
              <TableCell data-testid="name-key">
                <Skeleton variant="text" sx={{ width: '60%' }} />
              </TableCell>
              <TableCell align="right">
                <Skeleton variant="text" sx={{ width: '100%' }} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Accordion variant="card" defaultExpanded={true}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <CardMedia
          src={PersonalInfoImage.src}
          component="img"
          onError={(e: any) => {
            e.target.src = PersonalInfoImage.src;
          }}
        />
        <Stack>
          <Typography variant="h6">Personal Info</Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        {data?.user ? (
          <TableContainer>
            <Table aria-label="simple table">
              <TableBody>
                {/* @ts-ignore */}
                <TableRow key="name" variant="stack">
                  <TableCell data-testid="name-key">Name</TableCell>
                  <TableCell align="right" data-testid="name-value">
                    {data.user.name}
                  </TableCell>
                </TableRow>
                {/* @ts-ignore */}
                <TableRow key="email" variant="stack">
                  <TableCell data-testid="email-key">Email</TableCell>
                  <TableCell align="right" data-testid="email-value">
                    {data.user.email}
                  </TableCell>
                </TableRow>
                {/* @ts-ignore */}
                <TableRow key="phone" variant="stack">
                  <TableCell data-testid="phone-key">Phone</TableCell>
                  <TableCell align="right" data-testid="phone-value">
                    {data.user.phone}
                  </TableCell>
                </TableRow>
                {/* @ts-ignore */}
                <TableRow key="user" variant="stack">
                  <TableCell data-testid="user-key">User ID</TableCell>
                  <TableCell align="right" data-testid="user-value">
                    {data.user.prettyUserId}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <TableLoader />
        )}
      </AccordionDetails>
    </Accordion>
  );
}
