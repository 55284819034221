import Splash from '@/components/_common/Splash/Splash';
import { useAuth } from '@/context/AuthContextProvider';
import PhoneBg from '@/public/phone.svg';
import { START_VERIFY_ID } from '@/services/mutations';
import { useMutation } from '@apollo/client';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import CircularProgressButton from './CircularProgressButton';

type Props = {
  onComplete?: (data?: any) => void;
  refetch: () => void;
};

function VerifyIdDesktop({ onComplete, refetch }: Props) {
  const { userId } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [link, setLink] = useState(null);

  const [startVerifyId] = useMutation(START_VERIFY_ID);

  useEffect(() => {
    const successCallbackUrl = `/public/verify-id/complete`;
    const failureCallbackUrl = `/public/verify-id/error`;

    const input = {
      customerId: userId,
      successCallbackUrl: `${process.env.NEXT_PUBLIC_DOMAIN}${successCallbackUrl}`,
      failureCallbackUrl: `${process.env.NEXT_PUBLIC_DOMAIN}${failureCallbackUrl}`,
      mockVerify:
        process.env.NEXT_PUBLIC_APPLICATION_STAGE !== 'production'
          ? true
          : false,
    };

    startVerifyId({
      variables: { input: input },
      onCompleted: async (data: any) => {
        setIsLoading(false);
        setLink(data?.startVerifyId?.link);
      },
    });
  }, [userId, startVerifyId]);

  if (isLoading)
    return <Splash title="Loading..." footer={null} isLoading={true} />;

  if (link)
    return (
      <Splash
        title="You’ll need to do this on your mobile!"
        subtitle="Login using your mobile or scan this QR code to continue."
        content={
          <Box
            p={3}
            sx={{ borderRadius: '8px', bgcolor: '#ffffff', width: '244px' }}
          >
            <QRCode value={link} size={192} />
          </Box>
        }
        backgroundImage={PhoneBg}
        footer={
          <Stack direction="row" spacing={1}>
            <Button
              variant="text"
              size="large"
              type="submit"
              sx={{ width: '100%' }}
              startIcon={<ChevronLeft />}
              onClick={onComplete}
              data-testid="back"
            >
              I&#39;ll do this later
            </Button>
            <CircularProgressButton seconds={10} refetch={refetch} />
          </Stack>
        }
        isLoading={false}
      />
    );
}

export default VerifyIdDesktop;
