import DomainImage from '@/components/_common/DomainImage/DomainImage';
import { SIGN_PRIVACY_POLICY } from '@/services/mutations';
import { useMutation } from '@apollo/client';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Link from 'next/link';
import { Dispatch } from 'react';

type Checkbox = {
  name: string;
  imageUrl: string;
  label: React.ReactNode;
  privacyPolicyLink: string;
  checked: boolean;
};

type Props = {
  checkboxes: Checkbox[];
  setCheckboxes: Dispatch<Checkbox[]>;
};

function SignCompanyPrivacyPolicies({ checkboxes, setCheckboxes }: Props) {
  const [signPrivacyPolicy] = useMutation(SIGN_PRIVACY_POLICY);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    signPrivacyPolicy({
      variables: {
        input: {
          companySlug: event.target.name,
        },
      },
    });

    const updatedState = checkboxes.map((checkbox: any) => {
      if (checkbox.name === event.target.name) {
        return {
          ...checkbox,
          checked: !checkbox.checked,
        };
      } else {
        return checkbox;
      }
    });

    setCheckboxes(updatedState);
  };

  return (
    <Paper elevation={2} sx={{ p: 2, borderRadius: '24px', width: '100%' }}>
      <Stack sx={{ gap: 2 }}>
        <Typography variant="body2">
          Read and accept your property teams privacy policies to continue.
        </Typography>
        {!!checkboxes &&
          checkboxes.length > 0 &&
          checkboxes.map((checkbox: any) => (
            <Stack
              direction="row"
              alignItems="center"
              gap={2}
              key={checkbox.name}
            >
              <DomainImage
                src={checkbox.imageUrl}
                alt={checkbox.name}
                size="md"
              />
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkbox.checked}
                      onChange={handleChange}
                      name={checkbox.name}
                      data-testid={checkbox.name}
                    />
                  }
                  label={
                    <Typography variant="subtitle1">
                      I&apos;ve read and accept {`${checkbox.name}'s`}{' '}
                      <Link
                        href={checkbox.privacyPolicyLink || ''}
                        target="_blank"
                        style={{ textDecoration: 'underline' }}
                      >
                        privacy policy
                      </Link>
                    </Typography>
                  }
                  labelPlacement="end"
                />
              </FormControl>
            </Stack>
          ))}
      </Stack>
    </Paper>
  );
}

export default SignCompanyPrivacyPolicies;
