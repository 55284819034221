'use client';

import House from '@/public/house.svg';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Chip from '@mui/material/Chip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import Image from 'next/image';
import Link from 'next/link';
import * as React from 'react';
import { useEffect, useState } from 'react';

const PLACEHOLDER_IMAGE_URL = House.src;
const BLUR_IMAGE_URL =
  'data:image/jpg;base64,/9j/4AAQSkZJRgABAQAASABIAAD/2wBDAAIBAQEBAQIBAQECAgICAgQDAgICAgUEBAMEBgUGBgYFBgYGBwkIBgcJBwYGCAsICQoKCgoKBggLDAsKDAkKCgr/2wBDAQICAgICAgUDAwUKBwYHCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgr/wAARCAAKAAoDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAACAUG/8QAKxAAAQMCBAILAQAAAAAAAAAAAQIDBAUGAAcIERIhCRMVIiMyNUFCZHGB/8QAFQEBAQAAAAAAAAAAAAAAAAAABQb/xAAeEQABAgcBAAAAAAAAAAAAAAABAgQAAwUGESFBof/aAAwDAQACEQMRAD8AQuonpRcnoanZ+XeQdAjxG5nU9t1SqOcT69j4KorDCeFQO3yUCQQDtzNula5NCUymRpdz2NdEWpOsIXUY0S2lOtNPlILiELCe+kK3AV7gA4GNq+gRB99R/vLG5R5B+YMa3ZXxKCkziM8ijFuW642tsCR3Oz5H/9k=';

interface SubSideBarProps {
  links: { text: string; href: string; icon: React.ElementType }[];
  linksBottom?: { text: string; href: string; icon: React.ElementType }[];
  labels?: string[] | null;
  title: string;
  subTitle?: string | null;
  imageUrl: string;
  placeholderImageUrl?: string | null;
  blurImageUrl?: string | null;
  isLoading?: boolean;
  transactionData?: any;
}

/**
 * Component: Side Bar
 */
export default function SubSideBarV2({
  links,
  linksBottom,
  labels,
  title,
  subTitle,
  imageUrl,
  placeholderImageUrl,
  blurImageUrl,
  isLoading = false,
}: SubSideBarProps) {
  const theme = useTheme();
  const [image, setImage] = useState(imageUrl);

  useEffect(() => {
    if (imageUrl)
      setImage(imageUrl || placeholderImageUrl || PLACEHOLDER_IMAGE_URL);
  }, [imageUrl]);

  const listStyle = {
    width: '100%',
    '& .MuiButtonBase-root': {
      height: '48px',
      padding: '0 20px',
      gap: '8px',
      borderRadius: 2,
      color: theme.palette.primary.main,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& .MuiListItemIcon-root': {
      minWidth: '24px',
      color: theme.palette.primary.main,
    },
    gap: '8px',
    display: 'flex',
    flexDirection: 'column',
  };

  const cardMediaStyle = {
    width: 192,
    height: 192,
    minWidth: 192,
    minHeight: 192,
    borderRadius: 2,
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: theme.palette.background.paper,
    backgroundColor: theme.palette.background.paper,
    boxSizing: 'border-box',
    aspectRatio: '1',
    overflow: 'hidden',
    position: 'relative',
  };
  const SubSideBarLoader = () => {
    return (
      <Box
        p={2}
        sx={{
          width: 224,
          flexShrink: 0,
          borderRadius: 3,
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Skeleton
          variant="rounded"
          sx={{
            width: 192,
            height: 192,
            minWidth: 192,
            minHeight: 192,
            borderRadius: 2,
          }}
        />
        <Box sx={{ px: '16px', py: '16px' }}>
          <Typography variant="h6">
            <Skeleton variant="text" />
          </Typography>

          <Box sx={{ py: 1, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            <Skeleton
              variant="rectangular"
              sx={{ height: '24px', width: '48px' }}
            />
            <Skeleton
              variant="rectangular"
              sx={{ height: '24px', width: '32px' }}
            />
            <Skeleton
              variant="rectangular"
              sx={{ height: '24px', width: '64px' }}
            />
          </Box>

          <Typography variant="body2">
            <Skeleton variant="text" />
          </Typography>
        </Box>

        <Box sx={{ flexGrow: 1, height: '100%' }}>
          <List sx={listStyle}>
            <Skeleton
              variant="rounded"
              sx={{
                width: '100%',
                height: 48,
                borderRadius: 2,
              }}
            />
            <Skeleton
              variant="rounded"
              sx={{
                width: '100%',
                height: 48,
                borderRadius: 2,
              }}
            />
            <Skeleton
              variant="rounded"
              sx={{
                width: '100%',
                height: 48,
                borderRadius: 2,
              }}
            />
          </List>
        </Box>
      </Box>
    );
  };

  if (!isLoading)
    return (
      <Box
        p={2}
        sx={{
          width: 224,
          flexShrink: 0,
          borderRadius: 3,
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <CardMedia sx={cardMediaStyle}>
          <Image
            src={image}
            alt={title || 'kotini'}
            width={192}
            height={192}
            objectFit="cover"
            quality={30}
            placeholder="blur"
            blurDataURL={blurImageUrl || BLUR_IMAGE_URL}
            priority
            onError={() => {
              setImage(placeholderImageUrl || PLACEHOLDER_IMAGE_URL);
            }}
          />
        </CardMedia>
        <Box sx={{ px: '16px', py: '16px' }}>
          <Typography variant="h6">{title}</Typography>

          {labels && (
            <Box sx={{ py: 1, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {labels.length > 0 &&
                labels.map((label, index) => (
                  <Chip key={index} label={label} />
                ))}
            </Box>
          )}
          {subTitle && <Typography variant="body2">{subTitle}</Typography>}
        </Box>

        <Box sx={{ flexGrow: 1, height: '100%' }}>
          <List sx={listStyle}>
            {links.map(({ text, href, icon: Icon }) => (
              <Link href={href} key={text} passHref>
                <ListItem disablePadding>
                  <ListItemButton data-testid={text}>
                    <ListItemIcon>
                      <Icon />
                    </ListItemIcon>
                    <ListItemText primary={text} />
                  </ListItemButton>
                </ListItem>
              </Link>
            ))}
          </List>
        </Box>

        {linksBottom && (
          <List sx={listStyle} disablePadding>
            <ListItem disablePadding>
              <ListItemButton data-testid="more">
                <ListItemIcon>
                  <MoreHoriz />
                </ListItemIcon>
                <ListItemText primary={'More'} />
              </ListItemButton>
            </ListItem>
          </List>
        )}
      </Box>
    );

  return <SubSideBarLoader />;
}
