'use client';

import CardEmpty from '@/components/_common/CardEmpty/CardEmpty';
import CardLoader from '@/components/_common/CardLoader/CardLoader';
import { TRANSACTIONS_SUMMARY_V2 } from '@/services/queries';
import { useQuery } from '@apollo/client';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TransactionCard from './components/TransactionCard';

type Props = {
  transactionsType?: 'sale' | 'purchase';
};
/**
 * Component: Transaction Summary
 */
function TransactionSummary({ transactionsType }: Props) {
  const { data, loading, error, refetch } = useQuery(TRANSACTIONS_SUMMARY_V2, {
    notifyOnNetworkStatusChange: true,
  });
  const theme = useTheme();

  const generateTransactionSummary = () => {
    if (loading || !data) return <CardLoader />;

    if (transactions.length === 0 || data?.transactionsV1 === null)
      return (
        <CardEmpty title="No transactions" subTitle="Expecting to see one?" />
      );

    return transactions?.map((transaction: any) => (
      <TransactionCard
        transaction={transaction}
        key={transaction.transactionId}
      />
    ));
  };

  const transactions = !transactionsType
    ? data?.transactionsV1
    : data?.transactionsV1.filter(
        (transaction: any) => transactionsType === transaction.type
      );

  return (
    <Stack
      sx={{
        py: 2,
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        gap: 2,
        width: '100%',
      }}
    >
      <Stack
        direction="row"
        sx={{ justifyContent: 'space-between', width: '100%' }}
      >
        <Stack direction="row" sx={{ gap: 1, alignItems: 'flex-end' }}>
          <Typography
            variant="h4"
            sx={{
              color: loading
                ? theme.palette.divider
                : theme.palette.text.primary,
              pl: 2,
            }}
          >
            {transactionsType
              ? transactionsType === 'sale'
                ? 'Sales'
                : 'Purchases'
              : 'Transactions'}
          </Typography>
          {!loading && (
            <IconButton
              size="small"
              onClick={() => refetch()}
              data-testid="refresh"
            >
              <RefreshIcon />
            </IconButton>
          )}
        </Stack>
      </Stack>
      <Grid container spacing={1}>
        {generateTransactionSummary()}
      </Grid>
    </Stack>
  );
}

export default TransactionSummary;
