'use client';

import { useTaskGroup } from '@/context/TaskGroupContextProvider';
import LinearProgress from '@mui/material/LinearProgress';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import styles from '../taskGroup.module.scss';

type Props = {
  isRefetching: boolean;
};

function TaskGroupProgress({ isRefetching }: Props) {
  const {
    state: { tasks },
  } = useTaskGroup();

  const completedTasks = tasks.filter((task) => task.status === 'complete');

  const progress = (completedTasks.length / tasks.length) * 100;

  const ProgressLoader = () => {
    return (
      <>
        <Skeleton variant="text" width={100} height={16} />
        <Skeleton variant="text" height={4} />
      </>
    );
  };

  const Progress = () => {
    return (
      <>
        <Typography variant="subtitle2">{`${completedTasks.length} of ${tasks.length} completed`}</Typography>
        <LinearProgress
          variant={isRefetching ? 'indeterminate' : 'determinate'}
          value={progress}
          color="success"
        />
      </>
    );
  };

  return (
    <div className={styles.progress}>
      {isRefetching ? <ProgressLoader /> : <Progress />}
    </div>
  );
}

export default TaskGroupProgress;
