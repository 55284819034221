'use client';

import Splash from '@/components/_common/Splash/Splash';
import { Wizard } from '@/components/_common/Wizard/Wizard';
import { useAuth } from '@/context/AuthContextProvider';
import Broken from '@/public/broken.svg';
import {
  SUBMIT_DYNAMIC_CUSTOMER_FORM,
  SUBMIT_DYNAMIC_TRANSACTION_FORM,
} from '@/services/mutations';
import { wait } from '@/utils/helpers';
import { useMutation } from '@apollo/client';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import Button from '@mui/material/Button';
import { useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';

type Props = {
  type: 'customer' | 'transaction';
  transactionId?: string | null;
  formId: string;
  initialFormValues?: any;
};

/**
 * Component: Static Form
 */
function StaticForm({
  type,
  transactionId,
  formId,
  initialFormValues = {},
}: Props) {
  const router = useRouter();
  const { isAuthenticated } = useAuth();
  const [schema, setSchema] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [submitDynamicCustomerForm, { error: customerFormError }] = useMutation(
    SUBMIT_DYNAMIC_CUSTOMER_FORM,
    {
      refetchQueries: ['GetTasks', 'GetUser'],
      awaitRefetchQueries: true,
    }
  );

  const [submitDynamicTransactionForm, { error: transactionFormError }] =
    useMutation(SUBMIT_DYNAMIC_TRANSACTION_FORM, {
      refetchQueries: ['GetTasks', 'GetUser'],
      awaitRefetchQueries: true,
    });

  useEffect(() => {
    const fetchSchema = async () => {
      const response = await fetch(`/${formId}.json`);
      const jsonData = await response.json();
      setSchema({
        ...jsonData,
        formValues: { ...initialFormValues, ...jsonData.formValues },
      });
    };

    fetchSchema();
  }, [formId]);

  const handleSubmit = (values: any) => {
    const input = {
      formId: formId,
      formValues: {
        ...values,
        ...(transactionId && { transactionId: transactionId }),
      },
    };

    if (type === 'customer') {
      submitDynamicCustomerForm({
        variables: { input: input },
        onCompleted: async () => {
          await wait(2000);
        },
      });
    }
    if (type === 'transaction') {
      submitDynamicTransactionForm({
        variables: { input: input },
        onCompleted: async () => {
          await wait(3400);
        },
      });
    }
  };

  const handleRedirect = (url: any, target: any) => {
    setIsLoading(true);
    router.push(url);
  };

  const handleClose = () => {
    router.push('/');
  };

  if (customerFormError || transactionFormError)
    throw new Error(
      customerFormError?.message || transactionFormError?.message
    );

  if (!isAuthenticated || isLoading)
    return <Splash title="Loading..." footer={null} isLoading={true} />;

  if (schema === null) {
    return (
      <Splash
        title="Hmm..."
        subtitle="We can’t find this form right now - check again later."
        backgroundImage={Broken}
        footer={
          <Button
            variant="text"
            size="large"
            startIcon={<ChevronLeft />}
            sx={{ width: '100%' }}
            onClick={handleClose}
            data-testid="back"
          >
            I&#39;ll try again later
          </Button>
        }
        isLoading={isLoading}
      />
    );
  }

  return (
    <>
      {schema && (
        <Wizard
          data={schema}
          onSubmit={(values: any) => handleSubmit(values)}
          onClose={handleClose}
          onRedirect={(url: any, target: any) => handleRedirect(url, target)}
          maxWidth="450px"
          floatingWidgetRank="tertiary"
        />
      )}
    </>
  );
}

export default StaticForm;
