import { Loader } from '@/components/_common/Loader/Loader';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';

type Props = {
  title: string;
  information?: string;
  actions?: React.ReactNode;
  actionsDelay?: number;
};

function LoadingWidget({ title, information, actions, actionsDelay }: Props) {
  const theme = useTheme();

  return (
    <Stack
      sx={{
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '16px',
        p: '16px',
      }}
    >
      <Box sx={{ width: '77px' }}>
        <Loader
          mainColor={theme.palette.primary.main}
          tickColor={theme.palette.success.main}
          backgroundColor={'transparent'}
        />
      </Box>
      <Typography variant="h6">{title}</Typography>
      {information && <Typography variant="body1">{information}</Typography>}
      {actions && actionsDelay && (
        <Stack spacing={1} sx={{ mt: 1 }}>
          {actions}
        </Stack>
      )}
    </Stack>
  );
}

export default LoadingWidget;
