import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import TaskGroupProgress from './Progress';

function SideNavLoader() {
  return (
    <Stack
      sx={{
        gap: 1,
        justifyContent: 'space-between',
        height: '100%',
        width: { xs: '100%', sm: '280px' },
      }}
    >
      <Stack sx={{ gap: 1 }}>
        <Stack sx={{ gap: 1, p: 2 }}>
          <Stack direction="row" sx={{ p: 0, gap: 2, alignItems: 'center' }}>
            <Skeleton
              variant="rounded"
              sx={{ height: '64px', width: '64px', borderRadius: 1 }}
            />
            <Skeleton variant="text" sx={{ height: '24px', width: '64px' }} />
          </Stack>
          <Stack sx={{ gap: 0.5 }}>
            <Skeleton variant="text" sx={{ height: '16px', width: '100%' }} />
            <Skeleton variant="text" sx={{ height: '16px', width: '164px' }} />
          </Stack>
          <TaskGroupProgress isRefetching={true} />
        </Stack>
        <Stack sx={{ px: 2 }}>
          <Stack
            direction="row"
            sx={{ justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Skeleton variant="text" sx={{ height: '16px', width: '80px' }} />
            <Skeleton
              variant="rounded"
              sx={{
                height: '26px',
                width: '46px',
                borderRadius: '13px',
                marginRight: '6px',
              }}
            />
          </Stack>
        </Stack>
        <Stack sx={{ px: 1, gap: 1, alignItems: 'flex-start', width: '100%' }}>
          <Skeleton
            variant="rounded"
            sx={{ borderRadius: 2, width: '100%', height: '64px' }}
          />
          <Skeleton
            variant="rounded"
            sx={{ borderRadius: 2, width: '100%', height: '64px' }}
          />
          <Skeleton
            variant="rounded"
            sx={{ borderRadius: 2, width: '100%', height: '64px' }}
          />
        </Stack>
      </Stack>
      <Stack
        sx={{ pb: 1, px: 1, gap: 1, alignItems: 'flex-start', width: '100%' }}
      >
        <Skeleton
          variant="rounded"
          sx={{ borderRadius: 2, width: '100%', height: '64px' }}
        />
        <Skeleton
          variant="rounded"
          sx={{ borderRadius: 2, width: '100%', height: '64px' }}
        />
      </Stack>
    </Stack>
  );
}

export default SideNavLoader;
