'use client';

import House from '@/public/house.svg';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import Image from 'next/image';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import React, { useEffect, useState } from 'react';

const PLACEHOLDER_IMAGE_URL = House.src;
const BLUR_IMAGE_URL =
  'data:image/jpg;base64,/9j/4AAQSkZJRgABAQAASABIAAD/2wBDAAIBAQEBAQIBAQECAgICAgQDAgICAgUEBAMEBgUGBgYFBgYGBwkIBgcJBwYGCAsICQoKCgoKBggLDAsKDAkKCgr/2wBDAQICAgICAgUDAwUKBwYHCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgr/wAARCAAKAAoDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAACAUG/8QAKxAAAQMCBAILAQAAAAAAAAAAAQIDBAUGAAcIERIhCRMVIiMyNUFCZHGB/8QAFQEBAQAAAAAAAAAAAAAAAAAABQb/xAAeEQABAgcBAAAAAAAAAAAAAAABAgQAAwUGESFBof/aAAwDAQACEQMRAD8AQuonpRcnoanZ+XeQdAjxG5nU9t1SqOcT69j4KorDCeFQO3yUCQQDtzNula5NCUymRpdz2NdEWpOsIXUY0S2lOtNPlILiELCe+kK3AV7gA4GNq+gRB99R/vLG5R5B+YMa3ZXxKCkziM8ijFuW642tsCR3Oz5H/9k=';

interface SubAppBarProps {
  links: { text: string; href: string; icon: React.ElementType }[];
  linksBottom?: { text: string; href: string; icon: React.ElementType }[];
  labels?: string[] | null;
  title: string;
  subTitle?: string | null;
  imageUrl: string;
  placeholderImageUrl?: string | null;
  blurImageUrl?: string | null;
  isLoading?: boolean;
}

/**
 * Component: Side Bar
 */
export default function SubAppBarV2({
  links,
  linksBottom,
  labels,
  title,
  subTitle,
  imageUrl,
  placeholderImageUrl,
  blurImageUrl,
  isLoading = false,
}: SubAppBarProps) {
  const theme = useTheme();
  const [image, setImage] = useState(imageUrl);
  const [selectedLink, setSelectedLink] = useState<string | null>(null);
  const pathname = usePathname();

  useEffect(() => {
    const currentLink = links.find((link) => link.href === pathname);
    setSelectedLink(currentLink ? currentLink.text : null);
  }, [pathname, links]);

  useEffect(() => {
    if (imageUrl)
      setImage(imageUrl || placeholderImageUrl || PLACEHOLDER_IMAGE_URL);
  }, [imageUrl]);

  const SubAppBarLoader = () => {
    return (
      <Box
        p={2}
        sx={{
          width: '100%',
          borderRadius: 4,
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          backgroundColor: theme.palette.background.default,
          padding: '16px',
        }}
      >
        <Box
          sx={{ width: '100%', display: 'flex', flexDirection: 'row', gap: 3 }}
        >
          <Skeleton
            variant="rounded"
            sx={{
              width: 96,
              height: 96,
              minWidth: 96,
              minHeight: 96,
              borderRadius: 2,
            }}
          />
          <Stack
            sx={{
              width: '100%',
              flexGrow: 1,
              justifyContent: 'center',
              padding: 0,
            }}
          >
            <Typography variant="h6">
              <Skeleton variant="text" />
            </Typography>

            <Box sx={{ py: 1, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              <Skeleton
                variant="rectangular"
                sx={{ height: '24px', width: '48px' }}
              />
              <Skeleton
                variant="rectangular"
                sx={{ height: '24px', width: '32px' }}
              />
              <Skeleton
                variant="rectangular"
                sx={{ height: '24px', width: '64px' }}
              />
            </Box>
            <Typography variant="body2">
              <Skeleton variant="text" />
            </Typography>
          </Stack>
        </Box>
        <Skeleton
          variant="rounded"
          sx={{
            width: '100%',
            height: 57,
            borderRadius: 2,
          }}
        />
      </Box>
    );
  };

  if (!isLoading)
    return (
      <Box
        p={2}
        sx={{
          width: '100%',
          borderRadius: 4,
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          backgroundColor: theme.palette.background.default,
          padding: '16px',
        }}
      >
        <Box
          sx={{ width: '100%', display: 'flex', flexDirection: 'row', gap: 3 }}
        >
          <CardMedia
            sx={{
              width: 96,
              height: 96,
              minWidth: 96,
              minHeight: 96,
              borderRadius: 2,
              borderWidth: '2px',
              borderStyle: 'solid',
              borderColor: theme.palette.background.paper,
              backgroundColor: theme.palette.background.paper,
              flexGrow: 0,
              aspectRatio: '1',
              overflow: 'hidden',
              position: 'relative',
            }}
          >
            <Image
              src={image}
              alt={title || 'kotini'}
              width={96}
              height={96}
              quality={30}
              placeholder="blur"
              blurDataURL={blurImageUrl || BLUR_IMAGE_URL}
              priority
              onError={() => {
                setImage(placeholderImageUrl || PLACEHOLDER_IMAGE_URL);
              }}
            />
          </CardMedia>
          <Stack
            sx={{
              width: '100%',
              flexGrow: 1,
              justifyContent: 'center',
              padding: 0,
            }}
          >
            <Typography variant="h6">{title}</Typography>

            {labels && (
              <Box sx={{ py: 1, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {labels.length > 0 &&
                  labels.map((label, index) => (
                    <Chip key={index} label={label} />
                  ))}
              </Box>
            )}
            <Typography variant="body2">{subTitle}</Typography>
          </Stack>
        </Box>
        {links.length > 0 && (
          <Select
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              style: {
                top: '4px',
              },
            }}
            value={selectedLink || ''}
          >
            {links.map(({ text, href, icon: Icon }) => {
              return (
                <MenuItem
                  key={text}
                  sx={{ gap: 1 }}
                  value={text}
                  selected={pathname === href}
                  data-testid={text}
                >
                  <Link
                    href={href}
                    style={{
                      display: 'flex',
                      gap: '8px',
                      height: '100%',
                      margin: '-16px',
                      padding: '16px',
                      width: '100%',
                      background: 'transparent',
                    }}
                  >
                    <>
                      <Icon />
                      {text}
                    </>
                  </Link>
                </MenuItem>
              );
            })}
          </Select>
        )}
      </Box>
    );

  return <SubAppBarLoader />;
}
