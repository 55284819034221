import Icon from '@mui/material/Icon';
import Stack from '@mui/material/Stack';
import { alpha, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';

type Props = {
  title: string;
  information?: string;
  icon: React.ReactNode;
  color?: 'default' | 'success' | 'info' | 'warning' | 'error';
  actions?: React.ReactNode;
  actionsDelay?: number;
};

function SentimentWidget({
  title,
  information,
  icon,
  color = 'default',
  actions,
  actionsDelay,
}: Props) {
  const theme = useTheme();
  const colorMap = {
    default: theme.palette.text.primary,
    success: theme.palette.success.main,
    info: theme.palette.info.main,
    warning: theme.palette.warning.main,
    error: theme.palette.error.main,
  };

  return (
    <Stack
      sx={{
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 1,
        p: 4,
        backgroundColor:
          color !== 'default' ? alpha(colorMap[color], 0.1) : 'transparent',
      }}
    >
      <Icon sx={{ color: colorMap[color], fontSize: 77 }}>{icon}</Icon>
      <Typography
        variant="h3"
        textAlign={'center'}
        sx={{ color: colorMap[color] }}
      >
        {title}
      </Typography>
      {information && (
        <Typography
          variant="body1"
          textAlign={'center'}
          sx={{ color: colorMap[color] }}
        >
          {information}
        </Typography>
      )}
      {actions && actionsDelay && (
        <Stack spacing={1} sx={{ mt: 1 }}>
          {actions}
        </Stack>
      )}
    </Stack>
  );
}

export default SentimentWidget;
