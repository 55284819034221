'use client';

import Splash from '@/components/_common/Splash/Splash';
import { Wizard } from '@/components/_common/Wizard/Wizard';
import Broken from '@/public/broken.svg';
import { SUBMIT_FORM } from '@/services/mutations';
import { GET_PROPERTY_FORM } from '@/services/queries';
import { wait } from '@/utils/helpers';
import { useMutation, useQuery } from '@apollo/client';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import Button from '@mui/material/Button';
import { useRouter } from 'next/navigation';
import { useState } from 'react';

type Props = {
  transactionId: string;
  formId: string;
  initialFormValues?: any;
  onComplete?: (data?: any) => void;
  refetchQueries?: string[];
  waitClose?: number;
};

const WAIT_REFETCH_OFFSET = 5000;

/**
 * Component: Dynamic Transaction Form
 */
function DynamicTransactionForm({
  transactionId,
  formId,
  initialFormValues,
  onComplete,
  refetchQueries,
  waitClose = 2000,
}: Props) {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const delayRefetchedQuery = async (observableQuery: any) => {
    await wait(waitClose);
    observableQuery.refetch();
  };

  const { data, loading, error } = useQuery(GET_PROPERTY_FORM, {
    variables: {
      transactionId: transactionId,
      formId: formId,
    },
    fetchPolicy: 'no-cache',
  });

  const [submitForm, { error: mutationError }] = useMutation(SUBMIT_FORM, {
    refetchQueries: refetchQueries || [],
    onQueryUpdated: delayRefetchedQuery,
  });

  const handleSubmit = (values: any, formId: string, formValues: any) => {
    const parsedFormValues = JSON.parse(formValues);
    const input = {
      formId: formId,
      formValues: {
        ...parsedFormValues,
        ...values,
        transactionId: transactionId,
      },
    };

    submitForm({
      variables: { input: input },
    });
  };

  const handleRedirect = (url: any) => {
    setIsLoading(true);
    router.push(url);
  };

  const handleClose = async () => {
    setIsLoading(true);
    await wait(waitClose);
    onComplete && onComplete();
  };

  if (error || mutationError)
    throw new Error(error?.message || mutationError?.message);

  if (loading || isLoading)
    return <Splash title="Loading..." footer={null} isLoading={true} />;

  if (data && data.propertyForm === null) {
    return (
      <Splash
        title="Hmm..."
        subtitle="We can’t find this form right now - check again later."
        backgroundImage={Broken}
        footer={
          <Button
            variant="text"
            size="large"
            startIcon={<ChevronLeft />}
            sx={{ width: '100%' }}
            onClick={onComplete}
            data-testid="back"
          >
            I&#39;ll try again later
          </Button>
        }
        isLoading={loading}
      />
    );
  }

  return (
    <>
      {data && (
        <Wizard
          data={{
            ...data.propertyForm,
            formValues: {
              ...initialFormValues,
              ...JSON.parse(data.propertyForm.formValues),
            },
          }}
          onSubmit={(values: any) =>
            handleSubmit(
              values,
              data.propertyForm.formId,
              data.propertyForm.formValues
            )
          }
          onClose={handleClose}
          onRedirect={(url: any) => handleRedirect(url)}
          isLoading={loading}
          maxWidth="450px"
          floatingWidgetRank="tertiary"
        />
      )}
    </>
  );
}

export default DynamicTransactionForm;
