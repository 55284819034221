'use client';

import CardEmpty from '@/components/_common/CardEmpty/CardEmpty';
import CardLoader from '@/components/_common/CardLoader/CardLoader';
import { TEAM_SUMMARY } from '@/services/queries';
import { useQuery } from '@apollo/client';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TeamCard from './components/TeamCard';

/**
 * Component: Team Summary
 */
function TeamSummary() {
  const { data, loading, error, refetch } = useQuery(TEAM_SUMMARY, {
    notifyOnNetworkStatusChange: true,
  });
  const theme = useTheme();

  const generateTeamSummary = () => {
    if (loading || !data) return <CardLoader />;

    if (data?.teamSummary?.length === 0 || data?.teamSummary === null)
      return <CardEmpty title="No team" subTitle="Expecting to see one?" />;

    return data?.teamSummary?.map((member: any) => (
      <TeamCard member={member} key={member.slug} />
    ));
  };

  return (
    <Stack
      sx={{
        py: 2,
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        gap: 2,
        width: '100%',
      }}
    >
      <Stack
        direction="row"
        sx={{ justifyContent: 'space-between', width: '100%' }}
      >
        <Stack direction="row" sx={{ gap: 1, alignItems: 'flex-end' }}>
          <Typography
            variant="h4"
            sx={{
              color: loading
                ? theme.palette.divider
                : theme.palette.text.primary,
              pl: 2,
            }}
          >
            Team
          </Typography>
          {!loading && (
            <IconButton
              size="small"
              onClick={() => refetch()}
              data-testid="refresh"
            >
              <RefreshIcon />
            </IconButton>
          )}
        </Stack>
      </Stack>
      <Grid container spacing={1}>
        {generateTeamSummary()}
      </Grid>
    </Stack>
  );
}

export default TeamSummary;
