import { useTaskGroup } from '@/context/TaskGroupContextProvider';
import CloseIcon from '@mui/icons-material/Close';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

type Props = {
  onClose: () => void;
};

export default function BasicMenu({ onClose }: Props) {
  const {} = useTaskGroup();

  const handleClose = () => {
    onClose();
  };

  return (
    <Stack sx={{ width: '100%', gap: 1, p: 1, flex: 0 }}>
      <Button
        size="large"
        endIcon={<CloseIcon />}
        fullWidth
        onClick={handleClose}
        sx={{
          justifyContent: 'space-between',
        }}
        data-testid="close"
      >
        Close tasks
      </Button>
    </Stack>
  );
}
