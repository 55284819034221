'use client';

import { useTaskGroup } from '@/context/TaskGroupContextProvider';
import { Refresh } from '@mui/icons-material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';

type Props = {
  refetch: () => void;
  isRefetching: boolean;
};

function TaskGroupSwitch({ refetch }: Props) {
  const handleRefresh = () => {
    refetch();
  };
  const {
    state: { tasks, showCompleted },
    dispatch,
  } = useTaskGroup();

  const completedTasks = tasks.filter((task) => task.status === 'complete');

  return (
    <Stack
      direction="row"
      sx={{
        px: 1,
        gap: 1,
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Button
        onClick={handleRefresh}
        size="small"
        startIcon={<Refresh />}
        data-testid="taskgroup-refresh"
      >
        Refresh
      </Button>
      <Tooltip
        title={`${showCompleted ? 'Hide' : 'Show'} completed tasks`}
        placement="top"
      >
        <Switch
          size="medium"
          color="success"
          checked={showCompleted}
          onChange={() => {
            dispatch({ type: 'TOGGLE_COMPLETED' });
          }}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </Tooltip>
    </Stack>
  );
}

export default TaskGroupSwitch;
