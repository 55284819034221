import Box from '@mui/material/Box';
import styles from './cardsInfo.module.scss';

type Props = {
  children: React.ReactNode;
  isComplete: boolean;
};

function CardsInfo({ children, isComplete }: Props) {
  return (
    <Box
      className={`${styles.cardsInfo} ${isComplete ? styles.complete : ''}`}
      display="flex"
      alignItems="flex-start"
      alignSelf="stretch"
    >
      {children}
    </Box>
  );
}

export default CardsInfo;
