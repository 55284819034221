'use client';

import Splash from '@/components/_common/Splash/Splash';
import { useAuth } from '@/context/AuthContextProvider';
import { useTask } from '@/context/TaskContextProvider';
import ContractBg from '@/public/contract.svg';
import { TRANSACTION_CONTRACT_SUMMARY } from '@/services/queries';
import { useQuery } from '@apollo/client';
import LockIcon from '@mui/icons-material/Lock';
import Refresh from '@mui/icons-material/Refresh';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { useEffect, useState } from 'react';
import Contract from './components/Contract';
import Participants from './components/Participants';

type Props = {
  transactionId: string;
  companyId: string;
  onComplete?: (data?: any) => void;
};

function TransactionSignContracts({
  transactionId,
  companyId,
  onComplete,
}: Props) {
  const { userId } = useAuth();
  const {
    state: { activeStep, isComplete, isSubmitting },
    dispatch,
  } = useTask();
  const [participants, setParticipants] = useState<any[]>([]);
  const [currentParticipant, setCurrentParticipant] = useState<any>(null);
  const [participantsContractComplete, setParticipantsContractComplete] =
    useState<boolean>(false);

  const { data, loading, error, refetch } = useQuery(
    TRANSACTION_CONTRACT_SUMMARY,
    {
      variables: { transactionId: transactionId, companyId: companyId },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    }
  );

  useEffect(() => {
    if (data) {
      let contractParticipants: any[] = [];

      //spread the participants in each contract into the contractParticipants array
      data.transactionContractSummary.contracts.forEach((contract: any) => {
        contract.participants.forEach((participant: any) => {
          contractParticipants = [
            ...contractParticipants,
            {
              contractId: contract.contractId,
              name: contract.name,
              ...participant,
            },
          ];
        });
      });

      setParticipants(contractParticipants);
      setCurrentParticipant(
        contractParticipants.find((participant: any) => participant.currentUser)
      );
      setParticipantsContractComplete(
        contractParticipants.every((participant: any) => participant.signed)
      );
      dispatch({ type: 'SET_TOTAL_STEPS', payload: 1 });
      dispatch({ type: 'SET_ACTIVE_STEP', payload: 1 });
    }
  }, [data, dispatch, userId]);

  if (loading)
    return <Splash title="Loading..." footer={null} isLoading={true} />;

  if (isSubmitting)
    return (
      <Splash
        title="Saving your contract..."
        footer={
          <Button
            variant={'contained'}
            disabled={isSubmitting}
            size="large"
            endIcon={isSubmitting ? <LockIcon /> : null}
            sx={{ width: '100%' }}
            onClick={onComplete}
            data-testid="next"
          >
            Next
          </Button>
        }
        isLoading={isSubmitting}
      />
    );

  if (isComplete || (data && currentParticipant?.signed))
    return (
      <Splash
        title={
          participantsContractComplete
            ? 'Everyone’s signed their contract.'
            : 'You’ve signed your contract.'
        }
        subtitle={
          participantsContractComplete
            ? `${data.transactionContractSummary.company.name} is officially hired!`
            : 'Once everyone else has signed their contracts we’ll mark this task as complete.'
        }
        content={
          <Participants
            participants={participants}
            company={data.transactionContractSummary.company}
          />
        }
        backgroundImage={ContractBg}
        footer={
          <Stack gap={1} sx={{ flexDirection: 'row' }}>
            <IconButton
              color="secondary"
              size="large"
              sx={{ flex: 0 }}
              onClick={() => refetch()}
              data-testid="refresh"
            >
              <Refresh />
            </IconButton>
            <Button
              variant={'contained'}
              disabled={isSubmitting}
              size="large"
              endIcon={isSubmitting ? <LockIcon /> : null}
              sx={{ width: '100%', flex: 1 }}
              onClick={onComplete}
            >
              Next
            </Button>
          </Stack>
        }
        isLoading={isSubmitting}
        isSuccess={participantsContractComplete}
      />
    );

  if (data && currentParticipant)
    return (
      <>
        {activeStep === 1 && (
          <Contract
            contractId={currentParticipant.contractId}
            onComplete={onComplete}
          />
        )}
      </>
    );
}

export default TransactionSignContracts;
