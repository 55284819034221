'use client';

import { TRANSACTION_SUMMARY_V2 } from '@/services/queries';
import { titleCase } from '@/utils/formatters';
import { useQuery } from '@apollo/client';
import { Folder, Home, People } from '@mui/icons-material';
import SubNav from './SubNav';

/**
 * Component: Transaction Sub Navigation
 */
export default function TransactionSubNav({
  transactionId,
  children,
}: {
  transactionId: string;
  children: React.ReactNode;
}) {
  const { data, loading, error } = useQuery(TRANSACTION_SUMMARY_V2, {
    variables: {
      transactionId: transactionId,
    },
  });
  const links = [
    {
      text: 'Property',
      href: `/transactions/${transactionId}/property`,
      icon: Home,
    },
    {
      text: 'People',
      href: `/transactions/${transactionId}/people`,
      icon: People,
    },
    {
      text: 'Documents',
      href: `/transactions/${transactionId}/documents`,
      icon: Folder,
    },
  ];

  const title = data?.transactionV1.shortAddress;
  const subTitle = data?.transactionV1.property?.address;
  const id = data?.transactionV1.transactionId;
  const type = data?.transactionV1.type;
  const status = data?.transactionV1.status;
  const imageUrl = data?.transactionV1.imageUrl;

  const labels =
    id !== null || type !== null ? [status, type, id].filter(Boolean) : [];
  const titleCaseLabels = labels.map((label) => titleCase(label));

  return (
    <SubNav
      links={links}
      title={title}
      subTitle={subTitle}
      labels={titleCaseLabels}
      imageUrl={imageUrl}
      isLoading={loading}
    >
      {children}
    </SubNav>
  );
}
