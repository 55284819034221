import CardsInfo from '@/components/_common/CardsInfo/CardsInfo';
import UserPlaceholderImg from '@/public/user-placeholder.png';
import { reverseArray } from '@/utils/helpers';
import Check from '@mui/icons-material/Check';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

type Props = {
  participants: any;
};

function TransactionParticipantCards({ participants }: Props) {
  const isComplete = participants.every(
    (participant: any) => participant.hasInfo
  );

  const generateChip = (participant: any) => {
    if (participant.hasInfo)
      return <Chip label="Completed" size="small" icon={<Check />} />;
    return (
      <Chip label="Not started" size="small" icon={<HourglassBottomIcon />} />
    );
  };

  return (
    <CardsInfo isComplete={isComplete}>
      {reverseArray(participants).map((participant: any) => (
        <Grid item lg={2} md={3} xs={12} key={participant.id}>
          <Card variant="info" elevation={participant.hasInfo ? 1 : 0}>
            <CardHeader
              avatar={
                <CardMedia
                  component="img"
                  image={UserPlaceholderImg.src}
                ></CardMedia>
              }
            />
            <CardContent>
              <Typography variant="h6">{`Personal info.`}</Typography>
              <Typography variant="body2">{`${participant.firstName} ${
                participant.lastName
              } ${participant.currentUser ? '(me)' : ''}`}</Typography>
              {generateChip(participant)}
            </CardContent>
          </Card>
        </Grid>
      ))}
    </CardsInfo>
  );
}

export default TransactionParticipantCards;
