import { getHelp } from '@/utils/helpers';
import Close from '@mui/icons-material/Close';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LogoutFilled from '@mui/icons-material/Logout'; // Add this line
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

type SubCanvasProps = {
  children: React.ReactNode;
};

type SubCanvasHeaderProps = {
  title: string | null;
  onClose?: () => void;
  onHelp?: () => void;
};

type SubCanvasContentProps = {
  children: React.ReactNode;
};

type SubCanvasFooterProps = {
  children?: React.ReactNode;
};

type SubCanvasUserStatusProps = {
  email: string;
  company?: string;
  onLogout?: () => void;
};

type SubCanvasActionsProps = {
  children: React.ReactNode;
};

function SubCanvas({ children }: SubCanvasProps) {
  const theme = useTheme();

  return (
    <Paper
      elevation={3}
      sx={{
        width: '100%',
        height: '100%',
        borderRadius: 3,
        maxHeight: ['calc(100svh - 32px)', 'calc(100vh - 32px)'],
      }}
    >
      <Stack sx={{ height: '100%' }}>{children}</Stack>
    </Paper>
  );
}

SubCanvas.Header = function SubCanvasHeader({
  title,
  onClose,
  onHelp,
}: SubCanvasHeaderProps) {
  const HelpButton = () => {
    return (
      <Button
        variant="contained"
        color="secondary"
        size="medium"
        startIcon={<HelpOutlineIcon />}
        onClick={() => getHelp('get-help')}
        data-testid="get-help"
      >
        Help
      </Button>
    );
  };

  const CloseButton = () => {
    return (
      <Button
        variant="contained"
        color="secondary"
        size="medium"
        startIcon={<Close />}
        onClick={onClose}
        data-testid="close"
      >
        Close
      </Button>
    );
  };

  return (
    <Stack
      direction="row"
      sx={{
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexGrow: 0,
        p: 2,
      }}
    >
      <HelpButton />
      <Typography
        variant="h6"
        color="primary"
        sx={{ textAlign: 'center' }}
        noWrap
      >
        {title}
      </Typography>
      {onClose ? <CloseButton /> : <Box width={100}> </Box>}
    </Stack>
  );
};

SubCanvas.Content = function SubCanvasContent({
  children,
}: SubCanvasContentProps) {
  return (
    <Paper
      elevation={1}
      sx={{
        flexGrow: 1,
        borderRadius: 3,
      }}
    >
      {children}
    </Paper>
  );
};

SubCanvas.UserStatus = function SubCanvasUserStatus({
  email,
  company,
  onLogout,
}: SubCanvasUserStatusProps) {
  const theme = useTheme();

  return (
    <Stack
      direction={'row'}
      sx={{ px: '12px', py: '16px', gap: 1, alignItems: 'center' }}
    >
      <Typography variant="body1" color={theme.palette.text.secondary}>
        You&#39;re logged in as {email} {company && `(${company})`}
      </Typography>
      <IconButton color="secondary" size="small" onClick={onLogout}>
        <LogoutFilled />
      </IconButton>
    </Stack>
  );
};

SubCanvas.Footer = function SubCanvasFooter({
  children,
}: SubCanvasFooterProps) {
  return <Stack>{children}</Stack>;
};

SubCanvas.Actions = function SubCanvasActions({
  children,
}: SubCanvasActionsProps) {
  return (
    <Stack
      direction="row"
      sx={{
        width: '100%',
        p: '8px',
        gap: 1,
        justifyContent: 'center',
      }}
      id="subcanvas-footer"
    >
      {children}
    </Stack>
  );
};

export default SubCanvas;
