'use client';

import { useTask } from '@/context/TaskContextProvider';
import { useTaskGroup } from '@/context/TaskGroupContextProvider';
import TaskPlaceholderImg from '@/public/task-placeholder.png';
import { getTaskMetaUrl, isTaskRedirect } from '@/utils/helpers';
import CheckIcon from '@mui/icons-material/Check';
import ChevronRight from '@mui/icons-material/ChevronRight';
import LockIcon from '@mui/icons-material/Lock';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import Image from 'next/image';
import { useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';
import Menu from './Menu';
import TaskGroupProgress from './Progress';
import SideNavLoader from './SideNavLoader';
import TaskGroupSwitch from './Switch';

type Props = {
  taskGroup: any;
  onClose: () => void;
  helpDataTestId?: string;
  isRefetching: boolean;
  refetch: () => void;
  isLoading: boolean;
};

function TaskGroupSideNav({
  taskGroup,
  onClose,
  isRefetching,
  refetch,
  isLoading,
}: Props) {
  const router = useRouter();
  const {
    state: { tasks, showCompleted, isTaskOpen },
    dispatch,
  } = useTaskGroup();
  const { dispatch: taskDispatch } = useTask();
  const [taskGroupImage, setTaskGroupImage] = useState(TaskPlaceholderImg.src);
  const theme = useTheme();
  const getTasks: any = () => {
    if (showCompleted) {
      return tasks;
    }
    return tasks.filter((task) => task.status === 'pending');
  };

  const buttonIcon = (task: any) => {
    if (task.status === 'complete') return <CheckIcon />;
    if (task.predecessor !== null) return <LockIcon />;
    return <ChevronRight />;
  };

  useEffect(() => {
    if (taskGroup) setTaskGroupImage(taskGroup.image || TaskPlaceholderImg.src);
  }, [taskGroup]);

  // prefetch url routes for fater routing
  useEffect(() => {
    getTasks().map((task: any) => {
      const taskMeta = JSON.parse(task.meta);
      if (taskMeta?.type === 'url') router.prefetch(taskMeta.url);
    });
  }, []);

  const handleClick = (task: any) => {
    if (isTaskRedirect(task)) {
      router.push(getTaskMetaUrl(task));
      onClose();
      return;
    }
    if (task.status !== 'complete') {
      taskDispatch({ type: 'RESET' });
      dispatch({ type: 'SELECT_TASK', payload: task });
    }
  };

  const SideNav = () => {
    return (
      <Stack
        sx={{
          gap: 1,
          justifyContent: 'space-between',
          minHeight: 'fit-content',
          width: { xs: '100%', sm: '280px' },
        }}
      >
        <Stack sx={{ gap: 1, flex: 1, overflowY: 'hidden' }}>
          <Stack sx={{ gap: 1, p: 2 }}>
            {/* Header + info */}
            <Stack direction="row" sx={{ p: 0, gap: 2, alignItems: 'center' }}>
              <Image
                src={taskGroupImage}
                alt="task-image"
                objectFit="contain"
                width={64}
                height={64}
                priority={true}
                onError={() => {
                  setTaskGroupImage(TaskPlaceholderImg.src);
                }}
                style={{
                  width: '64px',
                  height: '64px',
                  borderRadius: '8px',
                  borderColor: theme.palette.background.paper,
                  borderWidth: '2px',
                  backgroundColor: theme.palette.background.default,
                }}
              />
              <Typography variant="h6">
                {taskGroup?.name || taskGroup?.title}
              </Typography>
            </Stack>
            <Typography variant="body2">{taskGroup.description}</Typography>
            <TaskGroupProgress isRefetching={isRefetching} />
          </Stack>

          {/* Switch */}
          <TaskGroupSwitch refetch={refetch} isRefetching={isRefetching} />

          {/* Task buttons */}
          {/* Button scroll outer */}
          <Stack
            sx={{
              mx: 0.5,
              gap: 1,
              height: '100%',
              flex: 1,
              overflowY: 'auto',
              boxSizing: 'border-box',
              '&::-webkit-scrollbar': {
                width: '8px',
              },
              '&::-webkit-scrollbar-track': { m: '8px' },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.divider,
              },
              '&::-webkit-scrollbar-thumb:hover': {
                backgroundColor: theme.palette.primary.main,
              },
            }}
          >
            {/* Button scroll inner */}
            <Stack sx={{ gap: 1, width: '100%', px: 0.5 }}>
              {getTasks().map((task: any) =>
                !isRefetching ? (
                  <Button
                    key={task.taskId}
                    variant={'contained'}
                    color={task.status === 'complete' ? 'success' : 'secondary'}
                    size="large"
                    endIcon={buttonIcon(task)}
                    disabled={task.predecessor !== null}
                    fullWidth
                    onClick={() => handleClick(task)}
                    sx={{
                      justifyContent: 'space-between',
                      textAlign: 'left',
                    }}
                    data-testid={task.name}
                  >
                    {task.name}
                  </Button>
                ) : (
                  <Skeleton
                    variant="rounded"
                    sx={{ borderRadius: 2 }}
                    width="100%"
                    height={64}
                    key={task.taskId}
                  />
                )
              )}
            </Stack>
          </Stack>
        </Stack>

        {/* Option buttons */}
        <Menu onClose={onClose} />
      </Stack>
    );
  };

  return (
    <Paper
      elevation={isLoading ? 0 : 1}
      sx={{
        display: { xs: isTaskOpen ? 'none' : 'flex', sm: 'flex' },
        borderRadius: 3,
        width: { xs: '100%', sm: 'fit-content' },
        minHeight: 'calc(100svh - 32px)',
        height: { xs: 'fit-content', sm: 'calc(100svh - 32px)' },
      }}
    >
      {isLoading ? <SideNavLoader /> : <SideNav />}
    </Paper>
  );
}

export default TaskGroupSideNav;
