'use-client';

import Splash from '@/components/_common/Splash/Splash';
import { useTask } from '@/context/TaskContextProvider';
import ContractBg from '@/public/contract.svg';
import { SIGN_CONTRACT } from '@/services/mutations';
import { GET_CONTRACT } from '@/services/queries';
import { wait } from '@/utils/helpers';
import { useMutation, useQuery } from '@apollo/client';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Image from 'next/image';
import { v4 as uuidv4 } from 'uuid';
import styles from '../transactionSignContracts.module.scss';
import Signature from './Signature';

type Props = {
  contractId: string;
  onComplete?: (data?: any) => void;
};
function Contract({ contractId, onComplete }: Props) {
  const { dispatch } = useTask();
  const theme = useTheme();
  const { data, loading, error, refetch } = useQuery(GET_CONTRACT, {
    variables: { contractId: contractId },
    fetchPolicy: 'network-only',
  });

  const [signContract] = useMutation(SIGN_CONTRACT, {
    refetchQueries: ['transactionContractSummary'],
  });

  const handleMutation = async (formData: any) => {
    const input = {
      contractId: uuidv4(),
      contractRequestId: data.contract.contractId,
      template: data.contract.template,
      companySlug: data.contract.company.slug,
      companyName: data.contract.company.name,
      text: data.contract.text,
      signature: formData.signature,
    };

    dispatch({ type: 'SET_IS_SUBMITTING', payload: true });

    signContract({
      variables: { input: input },
      onCompleted: async () => {
        await wait(5000);
        refetch();
        dispatch({ type: 'SET_IS_SUBMITTING', payload: false });
        dispatch({ type: 'SET_IS_COMPLETE', payload: true });
      },
    });
  };

  if (error) {
    if (error.message.includes('Cannot retrieve contract'))
      return (
        <Splash
          title="We need your seller info before you sign."
          subtitle="Go back to complete that task."
          backgroundImage={ContractBg}
          footer={
            <Button
              variant="text"
              size="large"
              startIcon={<ChevronLeft />}
              sx={{ width: '100%' }}
              onClick={onComplete}
              data-testid="back"
            >
              Go Back
            </Button>
          }
          isLoading={loading}
        />
      );
  }

  if (loading)
    return <Splash title="Loading..." footer={null} isLoading={true} />;

  if (data)
    return (
      <>
        <Stack
          sx={{
            px: { xs: 1, sm: 2, md: 4 },
            py: 4,
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
              width: '8px',
            },
            '&::-webkit-scrollbar-track': {
              margin: '8px 32px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: theme.palette.divider,
            },
            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: theme.palette.primary.main,
            },
          }}
        >
          <Paper sx={{ borderRadius: 0 }}>
            <Box
              sx={{
                border: '2px solid white',
                backgroundColor: data.contract.company?.brandColour,
              }}
            >
              <Image
                src={data.contract.company.logoUrl}
                priority={true}
                alt={`${data.contract.company.name} Logo`}
                className={styles.logo}
                width={122}
                height={122}
              />
            </Box>
            <Stack
              sx={{
                p: 4,
                gap: 2,
                width: '100%',
                '& h1': { marginBottom: '16px' },
                '& h2': {
                  marginTop: '32px',
                  marginBottom: '16px',
                },
                '& h3': { marginBottom: '16px' },
                '& p': { marginBottom: '16px' },
              }}
            >
              <section
                role="region"
                dangerouslySetInnerHTML={{ __html: data.contract.text }}
              ></section>
            </Stack>

            <div className={styles.contractForm}>
              <div className={styles.contractInput}>
                <Signature onSubmit={handleMutation} />
              </div>
            </div>
          </Paper>
        </Stack>
      </>
    );
}

export default Contract;
