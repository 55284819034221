'use client';

import CanvasV2 from '@/components/_layout/CanvasV2/CanvasV2';
import { useTask } from '@/context/TaskContextProvider';
import { useTaskGroup } from '@/context/TaskGroupContextProvider';
import dynamic from 'next/dynamic';
import { ComponentType, useMemo } from 'react';

type ComponentProps = any;
type Props = {
  refetch: () => void;
};

function TaskGroupTask({ refetch }: Props) {
  const {
    state: { currentTask },
    dispatch,
  } = useTaskGroup();
  const { dispatch: taskDispatch } = useTask();

  const meta = JSON.parse(currentTask?.meta) || { name: '', props: null };

  const props: ComponentProps = meta?.props || {};

  const Component = useMemo(
    () =>
      dynamic<ComponentType<ComponentProps>>(() =>
        import('@/components/_v2').then(
          //@ts-ignore
          (module) => module[meta?.name]
        )
      ),
    [meta?.name]
  );

  const handleOnComplete = () => {
    dispatch({ type: 'CLOSE_TASK' });
    taskDispatch({ type: 'RESET' });
    refetch();
  };

  return (
    <CanvasV2 title={currentTask?.name || ''} onClose={handleOnComplete}>
      <Component {...props} onComplete={handleOnComplete} />
    </CanvasV2>
  );
}

export default TaskGroupTask;
