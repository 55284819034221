import CompanyPlaceholderImg from '@/public/company-placeholder.png';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

type Props = {
  member: any;
};

function TeamCard({ member }: Props) {
  return (
    <Grid item xl={3} lg={4} md={6} sm={12} xs={6}>
      <Card elevation={0} data-testid={member.name}>
        <CardMedia image={member.logoUrl || CompanyPlaceholderImg.src} />
        <CardContent>
          <Typography variant="h6">{member.name}</Typography>
          <Chip
            label={member.service}
            color={member.status === 'active' ? 'default' : 'secondary'}
          />
        </CardContent>
      </Card>
    </Grid>
  );
}

export default TeamCard;
