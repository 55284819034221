import FormLayout from '@/components/_layout/Form/Form';
import { useTask } from '@/context/TaskContextProvider';
import { getHelp } from '@/utils/helpers';
import ChevronRight from '@mui/icons-material/ChevronRight';
import LockIcon from '@mui/icons-material/Lock';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { useEffect, useRef, useState } from 'react';
import SelectTitlesSkeleton from './SelectTitlesSkeleton';

type Props = {
  titles: any[];
  hasTitles: boolean;
  loading: boolean;
};

// utility function to group titles based on address
const transformTitles = (titles: any[]) => {
  const grouped = titles.reduce((acc: any, title: any) => {
    const key = title.address;
    return { ...acc, [key]: [...(acc[key] || []), title] };
  }, {});

  return Object.entries(grouped).map(([address, titles]) => ({
    address,
    titles,
  }));
};

/**
 * Sub-component: Select Titles
 */
function SelectTitles({ titles, hasTitles, loading = true }: Props) {
  const containerRef = useRef(null);
  const {
    state: { activeStep, isComplete, isSubmitting, formValues },
    dispatch,
  } = useTask();
  const [isScrollable, setIsScrollable] = useState(false);
  const [selectedTitleNumbers, setSelectedTitleNumbers] = useState<string[]>(
    []
  );

  const transformedTitles = transformTitles(titles);

  const handleClick = (titleNumber: string) => {
    setSelectedTitleNumbers((prevSelected: any) => {
      if (prevSelected.includes(titleNumber))
        return prevSelected.filter(function (title: any) {
          return title !== titleNumber;
        });
      return [...prevSelected, titleNumber];
    });
  };

  useEffect(() => {
    if (formValues.titleNumbers) {
      setSelectedTitleNumbers(formValues.titleNumbers);
    }
  }, [formValues]);

  useEffect(
    () =>
      setIsScrollable(
        // @ts-ignore
        containerRef?.current?.offsetHeight >= window.innerHeight
      ),
    [containerRef, loading]
  );

  const Content = () => {
    return (
      <Stack
        sx={{ gap: 2, width: '100%', maxWidth: { sm: '100%', md: '600px' } }}
        ref={containerRef}
      >
        <Stack gap={1} sx={{ px: 1 }}>
          <Typography variant="h6">
            Select all the titles you’re selling
          </Typography>
          <Typography variant="body1">
            If you own a share of freehold, or own the whole freehold of a flat
            make sure you select the leasehold and freehold titles for your
            property. There may also be titles to be selected for any garages
            and parking spaces that you own.
          </Typography>
        </Stack>
        <Titles />
        <Paper sx={{ p: 2, borderRadius: 3 }} elevation={0}>
          <Stack gap={1}>
            <Typography variant="h6" px={1}>
              Not sure?
            </Typography>
            <Typography variant="body1" px={1}>
              If you don&#39;t know which titles you&#39;re selling, or you
              can&#39;t find the correct ones, let us know and we&#39;ll get in
              touch to help you out.
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              size="medium"
              onClick={() => getHelp('get-help')}
              sx={{ width: { xs: '100%', sm: 'fit-content' } }}
            >
              Get help
            </Button>
          </Stack>
        </Paper>
      </Stack>
    );
  };

  const Titles = () => {
    if (loading) return <SelectTitlesSkeleton />;

    if (titles.length === 0)
      return (
        <Paper sx={{ p: 2, borderRadius: 3 }} elevation={0}>
          <Stack gap={1}>
            <Typography variant="h6" px={1}>
              No titles found
            </Typography>
            <Typography variant="body1" px={1}>
              HM Land Registry haven’t been able to provide the title documents
              for your property. This can happen if your property is
              unregistered or if they’re having trouble finding them.
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              size="medium"
              onClick={() => getHelp('get-help')}
              sx={{ width: { xs: '100%', sm: 'fit-content' } }}
            >
              My property isn&apos;t registered
            </Button>
          </Stack>
        </Paper>
      );

    return transformedTitles.map((title: any) => {
      return (
        <Paper sx={{ p: 2, borderRadius: 3 }} key={title.address}>
          <Stack gap={2}>
            <Typography variant="subtitle2" px={1}>
              {title.address}
            </Typography>
            <Table>
              <TableBody>
                {title.titles.map((title: any) => (
                  <TableRow
                    hover
                    onClick={() => handleClick(title.titleNumber)}
                    role="checkbox"
                    aria-checked={selectedTitleNumbers.includes(
                      title.titleNumber
                    )}
                    selected={selectedTitleNumbers.includes(title.titleNumber)}
                    key={title.titleNumber}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={selectedTitleNumbers.includes(
                          title.titleNumber
                        )}
                        inputProps={{
                          'aria-labelledby': title.titleNumber,
                        }}
                      />
                    </TableCell>
                    <TableCell width={100}>{title.tenure}</TableCell>
                    <TableCell align="left">{title.titleNumber}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Stack>
        </Paper>
      );
    });
  };

  const Footer = (
    <Stack gap={1} sx={{ flexDirection: 'row' }}>
      <Button
        variant={'contained'}
        disabled={selectedTitleNumbers.length === 0}
        size="large"
        endIcon={
          loading || selectedTitleNumbers.length === 0 ? (
            <LockIcon />
          ) : (
            <ChevronRight />
          )
        }
        sx={{ width: '100%', flex: 1 }}
        onClick={() =>
          dispatch({
            type: 'NEXT',
            payload: { titleNumbers: selectedTitleNumbers },
          })
        }
      >
        Next
      </Button>
    </Stack>
  );

  return (
    <FormLayout
      content={<Content />}
      footer={Footer}
      isScrollable={isScrollable}
    />
  );
}

export default SelectTitles;
