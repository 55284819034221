'use client';

import SubCanvas from '@/components/_layout/SubCanvas/SubCanvas';
import { getHelp } from '@/utils/helpers';
import { Box, Modal } from '@mui/material';
import Button from '@mui/material/Button';
import { useRouter } from 'next/navigation';
import { useState } from 'react';

function CreateTransactionV2() {
  const router = useRouter();
  const [show, setShow] = useState(false);

  const handleClose = () => {
    router.back();
  };

  const handleHelp = () => {
    getHelp('get-help');
  };

  const handleShowParticipantModal = () => {
    setShow(true);
  };

  return (
    <Box
      sx={{
        padding: '16px',
        width: '100%',
        height: ['100svh', '100vh'],
        backgroundColor: '#ffffff',
      }}
    >
      <SubCanvas>
        <SubCanvas.Header
          title={null}
          onClose={handleClose}
          onHelp={handleHelp}
        />
        <SubCanvas.Content>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            onClick={handleShowParticipantModal}
            fullWidth
          >
            Add participant
          </Button>
          <Modal
            open={show}
            onClose={handleClose}
            sx={{ width: '100%', height: '100%' }}
          >
            <p>This is the subcanvas for add participant</p>
          </Modal>
        </SubCanvas.Content>
        <SubCanvas.Footer>
          <SubCanvas.Actions>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={handleClose}
              fullWidth
            >
              Close
            </Button>
          </SubCanvas.Actions>
        </SubCanvas.Footer>
      </SubCanvas>
    </Box>
  );
}

export default CreateTransactionV2;
