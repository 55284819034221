'use client';

import { Loader } from '@/components/_common/Loader/Loader';
import { useTaskGroup } from '@/context/TaskGroupContextProvider';
import { GET_TASK_GROUP } from '@/services/queries';
import { NetworkStatus, useQuery } from '@apollo/client';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { Paper } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import { useEffect } from 'react';
import TaskGroupSideNav from './components/SideNav';
import TaskGroupTask from './components/Task';

type Props = {
  taskGroupId: string;
  onClose: () => void;
  helpDataTestId?: string;
};

function TaskGroup({
  taskGroupId,
  onClose,
  helpDataTestId = 'get-help',
}: Props) {
  const theme = useTheme();
  const {
    state: { currentTask, nextTask, isTaskOpen },
    dispatch,
  } = useTaskGroup();
  const { data, loading, error, refetch, networkStatus } = useQuery(
    GET_TASK_GROUP,
    {
      fetchPolicy: 'cache-and-network',
      variables: { taskGroupId: taskGroupId },
    }
  );

  useEffect(() => {
    if (data) {
      dispatch({
        type: 'SET_TASKS',
        payload: data?.taskGroup?.tasks,
      });
    }
  }, [data]);

  const isLoading = networkStatus === 1; // 1 is loading not refetching

  const generateTaskComponent = () => {
    if (isLoading) return <Loader />;
    if (currentTask === null)
      return (
        <Paper
          elevation={0}
          sx={{ width: '100%', height: '100%', borderRadius: 3 }}
        >
          <Stack
            sx={{
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {nextTask ? (
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={() =>
                  dispatch({ type: 'SELECT_TASK', payload: nextTask })
                }
                endIcon={<ChevronRight />}
                data-testid="nextTask"
              >
                Next task
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={onClose}
                endIcon={<ChevronRight />}
                data-testid="closeTaskGroup"
              >
                Close
              </Button>
            )}
          </Stack>
        </Paper>
      );
    return <TaskGroupTask refetch={refetch} />;
  };

  return (
    <Stack
      direction="row"
      sx={{
        width: '100%',
        height: '100%',
        gap: 2,
        p: 2,
        overflowY: { xs: 'scroll' },
        '&::-webkit-scrollbar': {
          width: '8px',
        },
        '&::-webkit-scrollbar-track': {
          margin: '0 32px',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: theme.palette.divider,
        },
        '&::-webkit-scrollbar-thumb:hover': {
          backgroundColor: theme.palette.primary.main,
        },
      }}
    >
      <TaskGroupSideNav
        taskGroup={data?.taskGroup}
        onClose={onClose}
        helpDataTestId={helpDataTestId}
        isRefetching={networkStatus === NetworkStatus.refetch}
        refetch={refetch}
        // @ts-ignore
        isLoading={isLoading}
      />
      <Box
        sx={{
          display: {
            xs: isTaskOpen ? 'flex' : 'none',
            sm: 'block',
          },
          width: '100%',
          height: '100%',
          position: 'sticky',
        }}
      >
        {generateTaskComponent()}
      </Box>
    </Stack>
  );
}

export default TaskGroup;
