'use client';

import { getHelp } from '@/utils/helpers';
import Add from '@mui/icons-material/Add';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Dashboard from '@mui/icons-material/Dashboard';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import LockIcon from '@mui/icons-material/Lock';
import Button from '@mui/material/Button';
import Link from 'next/link';

type Props = {
  transaction: any;
  locked: boolean;
  onSubmit: () => void;
  isLoading: boolean;
  connected: boolean;
};
function ConnectCaseSubCanvasFooter({
  transaction,
  locked,
  onSubmit,
  isLoading,
  connected,
}: Props) {
  const handleHelp = () => {
    getHelp('get-help');
  };
  if (isLoading) return null;

  if (connected)
    return (
      <Button
        component={Link}
        href={`/`}
        variant="contained"
        color="primary"
        size="large"
        endIcon={<Dashboard />}
        fullWidth
      >
        Dashboard
      </Button>
    );

  if (transaction)
    return (
      <Button
        variant="contained"
        color="primary"
        size="large"
        disabled={locked}
        endIcon={locked ? <LockIcon /> : <ChevronRight />}
        onClick={onSubmit}
        fullWidth
      >
        Confirm
      </Button>
    );

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        size="large"
        endIcon={<InfoOutlined />}
        onClick={handleHelp}
        fullWidth
      >
        Wrong address
      </Button>
      <Button
        component={Link}
        href={'/dashboard/create-transaction'}
        variant="contained"
        color="primary"
        size="large"
        endIcon={<Add />}
        fullWidth
      >
        Create transaction
      </Button>
    </>
  );
}

export default ConnectCaseSubCanvasFooter;
