import Splash from '@/components/_common/Splash/Splash';
import { useTask } from '@/context/TaskContextProvider';
import PhoneBg from '@/public/phone.svg';
import BadgeIcon from '@mui/icons-material/Badge';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import PersonIcon from '@mui/icons-material/Person';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';

type Props = {
  name: string;
  onComplete?: (data?: any) => void;
};

function Tips({ name, onComplete }: Props) {
  const { dispatch } = useTask();

  const TipsList = () => {
    return (
      <List>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <BadgeIcon />
            </ListItemIcon>
            <ListItemText primary="You take a clear, unobstructed photo of your ID document" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <HourglassBottomIcon />
            </ListItemIcon>
            <ListItemText primary="The photo of your ID is well lit without glare or blur " />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary={`You’re (${name}) present in the selfie`} />
          </ListItemButton>
        </ListItem>
      </List>
    );
  };

  return (
    <Splash
      title="Tips for your ID check"
      subtitle="You’ll need to take a photo of your ID then picture of yourself. For the best possible chance of passing your ID checks make sure..."
      subContent={<TipsList />}
      backgroundImage={PhoneBg}
      footer={
        <Stack direction="row" spacing={1}>
          <Button
            variant="text"
            size="large"
            type="submit"
            sx={{ width: '100%' }}
            startIcon={<ChevronLeft />}
            onClick={onComplete}
            data-testid="back"
          >
            I&#39;ll do this later
          </Button>
          <Button
            variant="contained"
            size="large"
            type="submit"
            sx={{ width: '100%' }}
            endIcon={<ChevronRight />}
            onClick={() => dispatch({ type: 'SET_ACTIVE_STEP', payload: 2 })}
            data-testid="next"
          >
            Next
          </Button>
        </Stack>
      }
      isLoading={false}
    />
  );
}

export default Tips;
