'use client';

import Box from '@mui/material/Box';
import { Theme, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import SubAppBar from './components/SubAppBar';
import SubSideBar from './components/SubSideBar';

interface SubNavProps {
  links: { text: string; href: string; icon: React.ElementType }[];
  linksBottom?: { text: string; href: string; icon: React.ElementType }[];
  labels?: string[] | null;
  title: string;
  subTitle?: string | null;
  imageUrl: string;
  placeholderImageUrl?: string | null;
  blurImageUrl?: string | null;
  children: React.ReactNode;
  inNav?: boolean;
  isLoading?: boolean;
}

export default function SubNav({
  links,
  linksBottom,
  labels,
  title,
  subTitle,
  imageUrl,
  placeholderImageUrl,
  blurImageUrl,
  children,
  isLoading = false,
  inNav = true,
}: SubNavProps): JSX.Element {
  const isXs = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        backgroundColor: {
          xs: 'transparent',
          sm: theme.palette.background.default,
        },

        minWidth: 'fit-content',
        borderRadius: 4,
        my: { xs: 0, sm: 2 },
        height: {
          xs: inNav ? 'calc(100vh - 32px)' : '100%',
          sm: 'calc(100vh - 32px)',
        },
        boxSizing: 'border-box',
        flexDirection: { xs: 'column', sm: 'row' },
        '*::-webkit-scrollbar': {
          width: '8px',
        },
        '*::-webkit-scrollbar-track': {
          my: '32px',
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: theme.palette.divider,
        },
        '&::-webkit-scrollbar-thumb:hover': {
          backgroundColor: theme.palette.primary.main,
        },
      }}
    >
      {isXs ? (
        // Render your component for 'xs' breakpoint
        <SubAppBar
          links={linksBottom ? links.concat(linksBottom) : links}
          labels={labels}
          title={title}
          subTitle={subTitle}
          imageUrl={imageUrl}
          placeholderImageUrl={placeholderImageUrl}
          blurImageUrl={blurImageUrl}
          isLoading={isLoading}
        />
      ) : (
        // Render your component for other breakpoints
        <SubSideBar
          links={links}
          linksBottom={linksBottom}
          labels={labels}
          title={title}
          subTitle={subTitle}
          imageUrl={imageUrl}
          placeholderImageUrl={placeholderImageUrl}
          blurImageUrl={blurImageUrl}
          isLoading={isLoading}
        />
      )}

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: '100%',
          borderRaius: 2,
          pr: { xs: 0, sm: 2 },
          overflowY: { xs: 'visible', sm: 'scroll' },
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
