import Box from '@mui/material/Box';

type Props = {
  color?: string;
};
function PoweredByKotini({ color = '#064550' }: Props) {
  return (
    <Box width={170}>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 198 28"
        xmlSpace="preserve"
      >
        <style type="text/css">{`.st0{fill:${color};`}</style>
        <path
          className="st0"
          d="M93.5,3.4h4.1v12l4.2-5.7h4.6l-4.7,6l5,6.7h-4.8l-4.2-6.1v6.1h-4.1V3.4z"
        />
        <path
          className="st0"
          d="M106.6,16c0-3.8,3-6.6,7.1-6.6c4.1,0,7.1,2.9,7.1,6.6c0,3.8-3,6.7-7.1,6.7C109.7,22.7,106.6,19.8,106.6,16z
	 M116.7,16c0-1.8-1.2-3-2.9-3c-1.7,0-2.9,1.2-2.9,3c0,1.8,1.2,3,2.9,3C115.5,19.1,116.7,17.8,116.7,16z"
        />
        <path
          className="st0"
          d="M124.4,13.1h-2.9V9.7h2.9V4.4h4.1v5.3h2.9v3.4h-2.9v9.3h-4.1V13.1z"
        />
        <path
          className="st0"
          d="M133.1,5.7c0-1.2,0.9-2.2,2.2-2.2c1.3,0,2.2,1,2.2,2.2c0,1.2-0.9,2.2-2.2,2.2C134,7.9,133.1,6.9,133.1,5.7z
	 M133.2,9.7h4.1v12.8h-4.1V9.7z"
        />
        <path
          className="st0"
          d="M140.2,9.7h4.1v1c0.9-0.8,2.2-1.3,3.6-1.3c2.9,0,4.9,2.2,4.9,4.9v8.2h-4.1v-7.3c0-1.3-0.9-2.2-2.2-2.2
	c-1.3,0-2.3,0.9-2.3,2.2v7.3h-4.1L140.2,9.7L140.2,9.7z"
        />
        <path
          className="st0"
          d="M155.3,5.7c0-1.2,0.9-2.2,2.2-2.2c1.3,0,2.2,1,2.2,2.2c0,1.2-0.9,2.2-2.2,2.2C156.2,7.9,155.3,6.9,155.3,5.7z
	 M155.4,9.7h4.1v12.8h-4.1V9.7z"
        />
        <path
          className="st0"
          d="M194.8,11.7l-11.1,11l-4-4l-2.7,2.7l6.7,6.6l13.9-13.7L194.8,11.7z"
        />
        <path
          className="st0"
          d="M187.6,9.3l2.7-2.7L183.6,0l-4.8,4.8V2.4h-3.9v6.1l-5.2,5.1l2.7,2.7l11.1-11L187.6,9.3z"
        />
        <path className="st0" d="M182.9,14.7h-2.6v2.5h2.6V14.7z" />
        <path className="st0" d="M186.9,10.8h-2.6v2.5h2.6V10.8z" />
        <path className="st0" d="M182.9,10.8h-2.6v2.5h2.6V10.8z" />
        <path className="st0" d="M186.9,14.7h-2.6v2.5h2.6V14.7z" />
        <path
          className="st0"
          d="M2.8,15.7v-1.3h3c0.4,0,0.8-0.1,1.1-0.3c0.3-0.2,0.6-0.4,0.8-0.7C7.9,13.1,8,12.7,8,12.3s-0.1-0.8-0.3-1.1
	c-0.2-0.3-0.4-0.5-0.8-0.7c-0.3-0.2-0.7-0.3-1.1-0.3h-3V8.9h3.1c0.7,0,1.3,0.1,1.8,0.4c0.5,0.3,1,0.7,1.3,1.2
	c0.3,0.5,0.5,1.1,0.5,1.8c0,0.7-0.2,1.3-0.5,1.8c-0.3,0.5-0.7,0.9-1.3,1.2c-0.5,0.3-1.1,0.4-1.8,0.4H2.8z M1.8,20V8.9h1.5V20H1.8z
	 M14.3,20.2c-0.7,0-1.4-0.2-2-0.5c-0.6-0.4-1.1-0.8-1.4-1.4c-0.4-0.6-0.5-1.3-0.5-2c0-0.7,0.2-1.4,0.5-2c0.4-0.6,0.8-1.1,1.4-1.4
	c0.6-0.4,1.3-0.5,2-0.5c0.7,0,1.4,0.2,2,0.5c0.6,0.3,1.1,0.8,1.4,1.4c0.4,0.6,0.5,1.3,0.5,2c0,0.7-0.2,1.4-0.5,2
	c-0.4,0.6-0.8,1.1-1.4,1.4C15.7,20,15,20.2,14.3,20.2z M14.3,18.8c0.5,0,0.9-0.1,1.3-0.3c0.4-0.2,0.7-0.5,0.9-0.9
	c0.2-0.4,0.3-0.8,0.3-1.3c0-0.5-0.1-0.9-0.3-1.3c-0.2-0.4-0.5-0.7-0.9-0.9c-0.4-0.2-0.8-0.3-1.3-0.3c-0.5,0-0.9,0.1-1.3,0.3
	c-0.4,0.2-0.7,0.5-0.9,0.9c-0.2,0.4-0.3,0.8-0.3,1.3c0,0.5,0.1,0.9,0.3,1.3c0.2,0.4,0.5,0.7,0.9,0.9C13.4,18.7,13.8,18.8,14.3,18.8z
	 M21.8,20l-3-7.6h1.5l2.2,5.9H22l2.2-5.9H25l2.2,5.9h-0.5l2.2-5.9h1.5l-3,7.6h-0.8l-2.2-5.7h0.5L22.7,20H21.8z M35,20.2
	c-0.8,0-1.4-0.2-2-0.5c-0.6-0.4-1.1-0.8-1.4-1.4c-0.4-0.6-0.5-1.3-0.5-2c0-0.7,0.2-1.4,0.5-2c0.4-0.6,0.8-1.1,1.4-1.4
	c0.6-0.4,1.3-0.5,2-0.5c0.7,0,1.3,0.2,1.9,0.5c0.5,0.3,1,0.8,1.3,1.3c0.3,0.6,0.5,1.2,0.5,1.9c0,0.1,0,0.2,0,0.4
	c0,0.1,0,0.3-0.1,0.4H32v-1.2h5.6L37.1,16c0-0.5-0.1-0.9-0.3-1.3c-0.2-0.4-0.4-0.6-0.8-0.8c-0.3-0.2-0.7-0.3-1.2-0.3
	c-0.5,0-0.9,0.1-1.3,0.3c-0.4,0.2-0.7,0.5-0.9,0.9c-0.2,0.4-0.3,0.8-0.3,1.4c0,0.5,0.1,1,0.3,1.4c0.2,0.4,0.5,0.7,0.9,0.9
	c0.4,0.2,0.8,0.3,1.4,0.3c0.4,0,0.8-0.1,1.2-0.2c0.4-0.1,0.7-0.4,0.9-0.7l0.9,0.9c-0.4,0.4-0.8,0.8-1.3,1C36.1,20,35.6,20.2,35,20.2
	z M40,20v-7.6h1.4V20H40z M41.5,15.7l-0.5-0.2c0-1,0.2-1.7,0.7-2.3c0.4-0.6,1.1-0.9,1.9-0.9c0.4,0,0.7,0.1,1,0.2
	c0.3,0.1,0.6,0.3,0.9,0.7l-0.9,1c-0.2-0.2-0.3-0.3-0.5-0.4s-0.4-0.1-0.7-0.1c-0.5,0-1,0.2-1.3,0.5C41.6,14.5,41.5,15,41.5,15.7z
	 M49.7,20.2c-0.8,0-1.4-0.2-2-0.5c-0.6-0.4-1.1-0.8-1.4-1.4c-0.4-0.6-0.5-1.3-0.5-2c0-0.7,0.2-1.4,0.5-2c0.4-0.6,0.8-1.1,1.4-1.4
	c0.6-0.4,1.3-0.5,2-0.5c0.7,0,1.3,0.2,1.9,0.5c0.5,0.3,1,0.8,1.3,1.3c0.3,0.6,0.5,1.2,0.5,1.9c0,0.1,0,0.2,0,0.4
	c0,0.1,0,0.3-0.1,0.4h-6.4v-1.2h5.6L51.8,16c0-0.5-0.1-0.9-0.3-1.3c-0.2-0.4-0.4-0.6-0.8-0.8c-0.3-0.2-0.7-0.3-1.2-0.3
	c-0.5,0-0.9,0.1-1.3,0.3c-0.4,0.2-0.7,0.5-0.9,0.9c-0.2,0.4-0.3,0.8-0.3,1.4c0,0.5,0.1,1,0.3,1.4c0.2,0.4,0.5,0.7,0.9,0.9
	c0.4,0.2,0.8,0.3,1.4,0.3c0.4,0,0.8-0.1,1.2-0.2c0.4-0.1,0.7-0.4,0.9-0.7l0.9,0.9c-0.4,0.4-0.8,0.8-1.3,1
	C50.9,20,50.3,20.2,49.7,20.2z M58,20.2c-0.7,0-1.3-0.2-1.9-0.5c-0.6-0.4-1-0.8-1.3-1.4c-0.3-0.6-0.5-1.3-0.5-2c0-0.7,0.2-1.4,0.5-2
	c0.3-0.6,0.8-1.1,1.3-1.4c0.6-0.4,1.2-0.5,1.9-0.5c0.6,0,1.1,0.1,1.5,0.4c0.5,0.2,0.8,0.6,1.1,1c0.3,0.4,0.4,0.9,0.5,1.5v2.3
	c0,0.5-0.2,1-0.5,1.5c-0.3,0.4-0.6,0.8-1.1,1C59.1,20,58.6,20.2,58,20.2z M58.2,18.8c0.5,0,0.9-0.1,1.2-0.3c0.4-0.2,0.6-0.5,0.8-0.9
	c0.2-0.4,0.3-0.8,0.3-1.3c0-0.5-0.1-1-0.3-1.4c-0.2-0.4-0.5-0.7-0.8-0.9c-0.4-0.2-0.8-0.3-1.2-0.3c-0.5,0-0.9,0.1-1.3,0.3
	c-0.4,0.2-0.7,0.5-0.9,0.9c-0.2,0.4-0.3,0.8-0.3,1.3c0,0.5,0.1,1,0.3,1.4c0.2,0.4,0.5,0.7,0.9,0.9C57.3,18.7,57.8,18.8,58.2,18.8z
	 M62,20h-1.5v-2l0.3-1.9l-0.3-1.8V8.6H62V20z M71.4,20.2c-0.6,0-1.1-0.1-1.6-0.4c-0.5-0.2-0.8-0.6-1.1-1c-0.3-0.4-0.4-0.9-0.5-1.5
	v-2.3c0-0.6,0.2-1,0.5-1.5c0.3-0.4,0.7-0.8,1.1-1c0.5-0.2,1-0.4,1.6-0.4c0.7,0,1.3,0.2,1.9,0.5c0.6,0.4,1,0.8,1.3,1.4
	c0.3,0.6,0.5,1.3,0.5,2c0,0.7-0.2,1.4-0.5,2c-0.3,0.6-0.8,1.1-1.3,1.4C72.7,20,72.1,20.2,71.4,20.2z M71.2,18.8
	c0.5,0,0.9-0.1,1.3-0.3c0.4-0.2,0.7-0.5,0.9-0.9c0.2-0.4,0.3-0.8,0.3-1.4c0-0.5-0.1-0.9-0.3-1.3c-0.2-0.4-0.5-0.7-0.9-0.9
	c-0.4-0.2-0.8-0.3-1.2-0.3c-0.5,0-0.9,0.1-1.3,0.3c-0.4,0.2-0.6,0.5-0.8,0.9c-0.2,0.4-0.3,0.8-0.3,1.4c0,0.5,0.1,0.9,0.3,1.3
	c0.2,0.4,0.5,0.7,0.8,0.9C70.3,18.7,70.7,18.8,71.2,18.8z M67.4,20V8.6h1.4v5.7l-0.3,1.8l0.3,1.9v2H67.4z M78.9,20.1l-3.3-7.7h1.6
	l2.4,6h-0.5l2.6-6h1.6l-3.6,7.7H78.9z M76.8,23.2l2.2-4.6l0.8,1.5l-1.4,3.1H76.8z"
        />
      </svg>
    </Box>
  );
}

export default PoweredByKotini;
