import CardsInfo from '@/components/_common/CardsInfo/CardsInfo';
import UserPlaceholderImg from '@/public/user-placeholder.png';
import { reverseArray } from '@/utils/helpers';
import DrawIcon from '@mui/icons-material/Draw';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CompanyPlaceholderImg from 'public/company-placeholder.png';

type Props = {
  participants: any;
  company: any;
};

function Participants({ participants, company }: Props) {
  const isComplete = participants.every(
    (participant: any) => participant.signed
  );

  const generateChip = (participant: any) => {
    if (participant.signed)
      return <Chip label="Signed" size="small" icon={<DrawIcon />} />;
    return (
      <Chip label="Not signed" size="small" icon={<HourglassBottomIcon />} />
    );
  };

  return (
    <CardsInfo isComplete={isComplete}>
      {reverseArray(participants).map((participant: any) => (
        <Grid item lg={2} md={3} xs={12} key={participant.id}>
          <Card variant="info" elevation={participant.signed ? 1 : 0}>
            <CardHeader
              avatar={
                <>
                  <CardMedia
                    component="img"
                    image={company.logoUrl}
                    onError={(e: any) => {
                      e.target.src = CompanyPlaceholderImg.src;
                    }}
                  />
                  <CardMedia
                    component="img"
                    image={UserPlaceholderImg.src}
                  ></CardMedia>
                </>
              }
            />
            <CardContent>
              <Typography variant="h6">{`${company.name} contract`}</Typography>
              <Typography variant="body2">{`${participant.firstName} ${
                participant.lastName
              } ${participant.currentUser ? '(me)' : ''}`}</Typography>
              {generateChip(participant)}
            </CardContent>
          </Card>
        </Grid>
      ))}
    </CardsInfo>
  );
}

export default Participants;
