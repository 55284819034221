import { Lock } from '@mui/icons-material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DrawIcon from '@mui/icons-material/Draw';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useEffect, useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import styles from '../transactionSignContracts.module.scss';

type Props = {
  onSubmit: (data: any) => void;
};

function Signature({ onSubmit }: Props) {
  const signatureCanvasRef = useRef(null);
  const [isEmpty, setIsEmpty] = useState(true);

  const handleClear = () => {
    // @ts-ignore
    signatureCanvasRef.current.clear();
    setIsEmpty(true);
  };

  const handleSave = () => {
    // @ts-ignore
    const signatureImage = signatureCanvasRef.current.toDataURL();
    onSubmit({ signature: signatureImage });
  };

  useEffect(() => {
    // @ts-ignore
    setIsEmpty(!!signatureCanvasRef?.current?.isEmpty());
  }, [signatureCanvasRef]);

  return (
    <Stack
      justifyContent="flex-end"
      alignItems="flex-end"
      alignSelf="stretch"
      p={2}
      gap={2}
      className={styles.signatureCanvasContainer}
    >
      <Typography variant="h6" sx={{ width: '100%', px: 1 }} color="primary">
        Sign here
      </Typography>
      <SignatureCanvas
        onBegin={() => {
          setIsEmpty(false);
        }}
        ref={signatureCanvasRef}
        // penColor="black"
        canvasProps={{
          className: styles.signatureCanvas,
        }}
      />
      <Stack direction="row" spacing={1}>
        <Button
          color="primary"
          variant="text"
          size="medium"
          type="submit"
          onClick={handleClear}
          endIcon={<DeleteOutlineIcon />}
          data-testid="clear"
        >
          Clear
        </Button>
        <Button
          color="primary"
          variant="contained"
          size="medium"
          type="submit"
          onClick={handleSave}
          endIcon={isEmpty ? <Lock /> : <DrawIcon />}
          data-testid="submit"
          disabled={isEmpty}
        >
          Submit
        </Button>
      </Stack>
    </Stack>
  );
}

export default Signature;
