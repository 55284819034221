'use client';

import AppLoader from '@/components/_common/AppLoader/AppLoader';
import Landing from '@/components/_layout/Landing/Landing';
import SubCanvas from '@/components/_layout/SubCanvas/SubCanvas';
import { CONNECT_TRANSACTION } from '@/services/mutations';
import { CASE, GET_USER_TRANSACTIONS } from '@/services/queries';
import { getHelp } from '@/utils/helpers';
import { useMutation, useQuery } from '@apollo/client';
import { useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';
import ConnectCaseSubCanvas from './components/ConnectCaseSubCanvas';
import ConnectCaseSubCanvasFooter from './components/ConnectCaseSubCanvasFooter';

type Props = {
  caseId: string;
};

type Transaction = {
  transactionId: string;
  type: string;
  property?: {
    uprn?: string;
  };
};

type Case = {
  type: string;
  property?: {
    uprn?: string;
  };
};

type Checkbox = {
  name: string;
  slug: string;
  imageUrl: string;
  checked: boolean;
};

const findCaseTransaction = (
  transactions: Transaction[],
  caseData: Case
): Transaction | undefined => {
  return transactions
    ?.filter((transaction) => transaction.type === caseData.type)
    .find((transaction) => {
      const transactionUprn = transaction?.property?.uprn;
      const caseUprn = caseData?.property?.uprn;
      return (
        transactionUprn !== null &&
        caseUprn !== null &&
        transactionUprn === caseUprn
      );
    });
};

function ConnectCaseToTransaction({ caseId }: Props) {
  const router = useRouter();
  const [connected, setConnected] = useState(false);
  const [checkboxes, setCheckboxes] = useState<Checkbox[]>([]);

  const {
    data: caseData,
    loading: caseLoading,
    error: caseError,
  } = useQuery(CASE, {
    variables: { caseId },
  });
  const { data: userTransactionsData, loading: userTransactionsLoading } =
    useQuery(GET_USER_TRANSACTIONS);
  const [connectTransaction, { loading: mutationLoading }] =
    useMutation(CONNECT_TRANSACTION);

  const caseDetails = caseData?.case;
  const company = caseDetails?.company;
  const transactions = userTransactionsData?.user?.transactions;
  const caseTransaction = findCaseTransaction(transactions, caseDetails);

  const handleClose = () => {
    router.push('/dashboard');
  };

  const handleHelp = () => {
    getHelp('get-help');
  };

  const handleMutation = () => {
    const input = {
      caseId: caseId,
      transactionId: caseTransaction?.transactionId,
    };

    connectTransaction({
      variables: {
        input,
      },
      onCompleted: () => {
        setConnected(true);
      },
    });
  };

  /*
    Set checkboxes when case data is loaded as
    we need to create a separate state to be shared between
    the subcanvas and the footer
  */
  useEffect(() => {
    if (caseData) {
      const companyCheckboxes = [
        {
          name: company?.tradingName,
          slug: company?.slug,
          imageUrl: company?.imageUrl,
          privacyPolicyLink: company?.privacyPolicyLink,
          checked: false,
        },
      ];

      setCheckboxes(companyCheckboxes);
    }
  }, [caseData]);

  if (
    !caseLoading &&
    !userTransactionsLoading &&
    !!caseData &&
    !!userTransactionsData
  )
    return (
      <Landing
        backgroundColor={company.settings.colourMain}
        contrastColor={company.settings.colourContrast}
      >
        <Landing.Branding poweredBy={true}>
          <Landing.Image src={company.imageUrl} title={company.tradingName} />
          <Landing.Title text={company.tradingName} />
        </Landing.Branding>
        <Landing.Content>
          <SubCanvas>
            <SubCanvas.Header
              title={null}
              onClose={handleClose}
              onHelp={handleHelp}
            />
            <SubCanvas.Content>
              <ConnectCaseSubCanvas
                transaction={caseTransaction}
                caseData={caseDetails}
                checkboxes={checkboxes}
                setCheckboxes={setCheckboxes}
                isLoading={mutationLoading}
                connected={connected}
              />
            </SubCanvas.Content>
            <SubCanvas.Footer>
              <SubCanvas.Actions>
                <ConnectCaseSubCanvasFooter
                  transaction={caseTransaction}
                  locked={
                    !checkboxes.every((checkbox: any) => checkbox.checked)
                  }
                  onSubmit={handleMutation}
                  isLoading={mutationLoading}
                  connected={connected}
                />
              </SubCanvas.Actions>
            </SubCanvas.Footer>
          </SubCanvas>
        </Landing.Content>
      </Landing>
    );

  return <AppLoader />;
}

export default ConnectCaseToTransaction;
