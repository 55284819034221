import FormLayout from '@/components/_layout/Form/Form';
import { useTask } from '@/context/TaskContextProvider';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { useEffect, useRef, useState } from 'react';

type Props = {
  titles: any[];
  onSubmit: () => void;
};

/**
 * Sub-component: Review Titles
 */
function ReviewTitles({ titles, onSubmit }: Props) {
  const {
    state: { formValues },
    dispatch,
  } = useTask();
  const [isScrollable, setIsScrollable] = useState(false);
  const containerRef = useRef(null);
  useEffect(
    () =>
      setIsScrollable(
        // @ts-ignore
        containerRef?.current?.offsetHeight >= window.innerHeight
      ),
    [containerRef]
  );

  const transformReviewTitles = (titles: any[]) => {
    const filteredTitles = titles.filter((title: any) =>
      formValues.titleNumbers.includes(title.titleNumber)
    );
    const grouped = filteredTitles.reduce((acc: any, title: any) => {
      const key = title.address;
      return { ...acc, [key]: [...(acc[key] || []), title] };
    }, {});

    return Object.entries(grouped).map(([address, titles]) => ({
      address,
      titles,
    }));
  };

  const transformedeviewTitles = transformReviewTitles(titles);

  const Content = () => {
    return (
      <Stack gap={2}>
        <Stack gap={1} sx={{ px: 1 }}>
          <Typography variant="h6">
            Review and confirm the titles you’re selling
          </Typography>
          <Typography variant="body1">
            Make sure you’ve selected the freehold as well as leasehold title if
            you own both and any titles for garages and parking spaces you own
            if they were shown.
          </Typography>
        </Stack>
        <Titles />
      </Stack>
    );
  };

  const Titles = () => {
    return transformedeviewTitles.map((title: any) => {
      return (
        <Paper key={title.address} sx={{ p: 2, borderRadius: 3 }}>
          <Stack gap={2}>
            <Typography variant="subtitle2" px={1}>
              {title.address}
            </Typography>
            <Table>
              <TableBody>
                {title.titles.map((title: any) => (
                  <TableRow key={title.titleNumber} hover={false}>
                    <TableCell width={100}>{title.tenure}</TableCell>
                    <TableCell align="left">{title.titleNumber}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Stack>
        </Paper>
      );
    });
  };

  const Footer = (
    <Stack gap={1} sx={{ flexDirection: 'row' }}>
      <IconButton
        color="secondary"
        size="large"
        sx={{ flex: 0 }}
        onClick={() =>
          dispatch({
            type: 'BACK',
          })
        }
        data-testid="back"
      >
        <ChevronLeft />
      </IconButton>
      <Button
        variant={'contained'}
        size="large"
        endIcon={<ChevronRight />}
        sx={{ width: '100%', flex: 1 }}
        onClick={onSubmit}
        data-testid="confirm"
      >
        Confirm
      </Button>
    </Stack>
  );

  return (
    <FormLayout
      content={<Content />}
      footer={Footer}
      isScrollable={isScrollable}
    />
  );
}

export default ReviewTitles;
