'use client';

import House from '@/public/house.svg';
import { colorToBase64Image } from '@/utils/helpers';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Image from 'next/image';
import { useState } from 'react';

type DomainImageProps = {
  src: string;
  alt: string;
  size: 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl';
  objectFit?: 'cover' | 'contain' | 'fill' | 'none' | 'scale-down';
  quality?: number;
  placeholder?: 'blur' | 'empty';
  priority?: boolean;
  placeholderImageUrl?: string;
};

const sizeMap = {
  sm: 24,
  md: 48,
  lg: 64,
  xl: 96,
  xxl: 128,
  xxxl: 200,
};

const borderRadiusMap = {
  sm: 1,
  md: 1,
  lg: 1,
  xl: 1,
  xxl: 2,
  xxxl: 3,
};

// Common component: DomainImage
const DomainImage = ({
  src,
  alt,
  size,
  objectFit = 'cover',
  quality = 30,
  placeholder = 'blur',
  priority = true,
  placeholderImageUrl = House.src,
}: DomainImageProps) => {
  const theme = useTheme();
  const [image, setImage] = useState(src || placeholderImageUrl);

  const width = sizeMap[size];
  const height = sizeMap[size];
  const borderRadius = borderRadiusMap[size];

  return (
    <Box
      sx={{
        width: width,
        height: height,
        minWidth: width,
        minHeight: height,
        borderRadius: borderRadius,
        boxSizing: 'border-box',
        aspectRatio: '1',
        overflow: 'hidden',
      }}
    >
      <Image
        src={image}
        alt={alt}
        width={width}
        height={height}
        objectFit={objectFit}
        quality={quality}
        placeholder={placeholder}
        blurDataURL={colorToBase64Image(theme.palette.divider)}
        priority={priority}
        onError={() => {
          setImage(placeholderImageUrl);
        }}
      />
    </Box>
  );
};

export default DomainImage;
