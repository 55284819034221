'use client';

import AccountImage from '@/public/user-placeholder.svg';
import { GET_USER } from '@/services/queries';
import { useQuery } from '@apollo/client';
import DescriptionIcon from '@mui/icons-material/Description';
import FolderIcon from '@mui/icons-material/Folder';
import PersonIcon from '@mui/icons-material/Person';
import SubNav from './SubNav';

/**
 * Component: Account Sub Navigation
 */
export default function AccountSubNav({
  children,
}: {
  children: React.ReactNode;
}) {
  const { data, loading, error } = useQuery(GET_USER);

  const links = [
    { text: 'Account', href: '/account/personal', icon: PersonIcon },
    { text: 'Documents', href: '/account/documents', icon: FolderIcon },
    { text: 'Privacy policy', href: '/account/privacy', icon: DescriptionIcon },
  ];

  const title = data?.user.name;
  const id = data?.user.prettyUserId;

  return (
    <SubNav
      links={links}
      title={title}
      labels={[id]}
      imageUrl={AccountImage.src}
    >
      {children}
    </SubNav>
  );
}
