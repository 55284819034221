'use client';

import CanvasV2 from '@/components/_layout/CanvasV2/CanvasV2';
import { useCanvas } from '@/context/CanvasContextProvider';
import { useTask } from '@/context/TaskContextProvider';
import dynamic from 'next/dynamic';
import { ComponentType, useMemo } from 'react';

type ComponentProps = any;
type Props = {
  task: any;
  refetchTodo: () => void;
  onClose: () => void;
};

function Task({ task, refetchTodo, onClose }: Props) {
  const { dispatch: taskDispatch } = useTask();
  const { setShowCanvas } = useCanvas();

  const meta = JSON.parse(task?.meta) || { name: '', props: null };

  const props: ComponentProps = meta?.props || {};

  const Component = useMemo(
    () =>
      dynamic<ComponentType<ComponentProps>>(() =>
        import('@/components/_v2').then(
          //@ts-ignore
          (module) => module[meta?.name]
        )
      ),
    [meta?.name]
  );

  const handleOnComplete = () => {
    taskDispatch({ type: 'RESET' });
    refetchTodo();
    onClose();
    setShowCanvas(false);
  };

  return (
    <CanvasV2 title={task?.name || ''} onClose={handleOnComplete}>
      <Component
        {...props}
        task={task}
        onComplete={handleOnComplete}
        onClose={handleOnComplete}
      />
    </CanvasV2>
  );
}

export default Task;
