'use client';

import { GET_PUBLIC_TRANSACTION } from '@/services/queries';
import { titleCase } from '@/utils/formatters';
import { useQuery } from '@apollo/client';

import SubNav from './SubNav';

/**
 * Component: Company Transaction Sub Navigation
 */
export default function CompanyTransactionSubNav({
  transactionId,
  company,
  children,
}: {
  transactionId: string;
  company: string;
  children: React.ReactNode;
}) {
  const { data, loading, error } = useQuery(GET_PUBLIC_TRANSACTION, {
    variables: {
      transactionId: transactionId,
    },
    skip: !transactionId,
  });
  const imageUrl = `https://kotini-public.s3.eu-west-1.amazonaws.com/images/company/logo/thumbnail/${company}.png`;

  const title = data?.transactionPublic.propertyName;
  const subTitle = data?.transactionPublic.property?.propertyAddress;
  const id = data?.transactionPublic.prettyTransactionId;
  const status = data?.transactionPublic.status;

  const labels = id !== null ? [status, id].filter(Boolean) : [];
  const titleCaseLabels = labels.map((label) => titleCase(label));

  return (
    <SubNav
      links={[]}
      title={title}
      subTitle={subTitle}
      labels={titleCaseLabels}
      imageUrl={imageUrl}
      inNav={false}
    >
      {children}
    </SubNav>
  );
}
