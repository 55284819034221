'use client';

import ActionsImage from '@/public/Actions.svg';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useAuth } from '@/context/AuthContextProvider';
import { getHelp } from '@/utils/helpers';
import { Info, Logout, Refresh } from '@mui/icons-material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

/**
 * Component: Reset Password
 */
export default function ResetPassword() {
  const { login, logout } = useAuth();

  return (
    <Accordion variant="card" defaultExpanded={true}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <CardMedia
          component="img"
          src={ActionsImage.src}
          onError={(e: any) => {
            e.target.src = ActionsImage.src;
          }}
        />
        <Stack>
          <Typography variant="h6">Actions</Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer>
          <Table aria-label="actions">
            <TableBody>
              <TableRow key="name" onClick={logout} sx={{ cursor: 'pointer' }}>
                <TableCell data-testid="logout-key">Logout</TableCell>
                <TableCell
                  align="right"
                  data-testid="logout-value"
                  sx={{ p: 1 }}
                >
                  <IconButton
                    color="secondary"
                    size="small"
                    data-testid="logout"
                  >
                    <Logout />
                  </IconButton>
                </TableCell>
              </TableRow>
              <TableRow
                key="name"
                onClick={() => {
                  login({
                    action: 'UPDATE_PASSWORD',
                  });
                }}
                sx={{ cursor: 'pointer' }}
              >
                <TableCell>Reset password</TableCell>
                <TableCell
                  align="right"
                  data-testid="reset-password"
                  sx={{ p: 1 }}
                >
                  <IconButton
                    color="secondary"
                    size="small"
                    data-testid="reset"
                  >
                    <Refresh />
                  </IconButton>
                </TableCell>
              </TableRow>
              <TableRow
                key="name"
                onClick={() => getHelp('get-help')}
                sx={{ cursor: 'pointer' }}
              >
                <TableCell scope="row">Help</TableCell>
                <TableCell
                  align="right"
                  data-testid="logout-value"
                  sx={{ p: 1 }}
                >
                  <IconButton color="secondary" size="small">
                    <Info />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
}
