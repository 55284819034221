import Splash from '@/components/_common/Splash/Splash';
import Passport from '@/public/passport.svg';
import PhoneBg from '@/public/phone.svg';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Image from 'next/image';

type Props = {
  name: string;
  onComplete?: (data?: any) => void;
  onSubmit: () => void;
};

function VerifyIdMobile({ name, onComplete, onSubmit }: Props) {
  return (
    <Splash
      title={`${name}, we’re about to check your ID.`}
      subtitle={`Make sure you your have your (${name}) passport or drivers license to hand. You’ll also need to take a selfie to prove it’s your ID - you can’t do this for someone else.`}
      content={
        <Image
          src={Passport}
          priority={true}
          alt={`Passport image`}
          width={192}
          height={192}
        />
      }
      backgroundImage={PhoneBg}
      footer={
        <Stack direction="row" spacing={1}>
          <Button
            variant="text"
            size="large"
            type="submit"
            sx={{ width: '100%' }}
            startIcon={<ChevronLeft />}
            onClick={onComplete}
            data-testid="back"
          >
            I&#39;ll do this later
          </Button>
          {/* remove this after testing */}
          <Button
            variant="contained"
            size="large"
            type="submit"
            sx={{ width: '100%' }}
            endIcon={<ChevronRight />}
            onClick={onSubmit}
            data-testid="next"
          >
            I have my ID
          </Button>
        </Stack>
      }
      isLoading={false}
    />
  );
}

export default VerifyIdMobile;
