'use client';

import CardLine from '@/components/_common/CardLine/CardLine';
import ContentColumn from '@/components/_common/ContentColumn/ContentColumn';
import LoadingWidget from '@/components/_common/LoadingWidget/LoadingWidget';
import SentimentWidget from '@/components/_common/SentimentWidget/SentimentWidget';
import SignCompanyPrivacyPolicies from '@/components/_v2/JoinTransaction/components/SignCompanyPrivacyPolicies';
import Typography from '@mui/material/Typography';

type Props = {
  transaction: any;
  caseData: any;
  checkboxes: any;
  setCheckboxes: any;
  isLoading: boolean;
  connected: boolean;
};
function ConnectCaseSubCanvas({
  transaction,
  caseData,
  checkboxes,
  setCheckboxes,
  isLoading,
  connected,
}: Props) {
  const companyTradingName = caseData.company.tradingName;
  const headline = transaction
    ? `Now let's add ${companyTradingName} to your property team`
    : `Let's start by creating your transaction for`;

  const generateProperty = () => {
    const context = transaction || caseData;
    const property = context?.property;

    if (!context || !property) {
      return null;
    }

    console.log('property', property);

    return (
      <CardLine>
        <CardLine.Media
          src={context.imageUrl}
          alt={`Image of ${property.line1}`}
        />
        <CardLine.Content>
          <CardLine.Title>{property.line1}</CardLine.Title>
          <CardLine.Text>{property.town}</CardLine.Text>
          <CardLine.Text>{property.postcode}</CardLine.Text>
        </CardLine.Content>
      </CardLine>
    );
  };

  if (isLoading)
    return (
      <LoadingWidget title={`Adding ${companyTradingName} to your team...`} />
    );

  if (connected)
    return (
      <SentimentWidget
        title={`${companyTradingName} added to your team`}
        information={`Head to your dashboard to get started on your home-moving tasks!`}
        icon={'check'}
        color="success"
      />
    );

  return (
    <ContentColumn variant="center">
      <Typography variant="h4" textAlign={'center'}>
        {headline}
      </Typography>
      {generateProperty()}
      {transaction && (
        <SignCompanyPrivacyPolicies
          checkboxes={checkboxes}
          setCheckboxes={setCheckboxes}
        />
      )}
    </ContentColumn>
  );
}

export default ConnectCaseSubCanvas;
