import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

type Props = {
  title: string;
  subTitle?: string;
};

function CardEmpty({ title, subTitle }: Props) {
  return (
    <Grid item xl={3} lg={4} md={6} sm={12} xs={6}>
      <Card
        elevation={0}
        variant="vacant"
        data-testid="get-help"
        className="hover"
      >
        <CardActionArea>
          <Typography variant="h6">{title}</Typography>
          <Typography variant="body2" color="primary">
            {subTitle}
          </Typography>
        </CardActionArea>
      </Card>
    </Grid>
  );
}

export default CardEmpty;
