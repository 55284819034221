import Stack from '@mui/material/Stack';

type ContentColumnProps = {
  children: React.ReactNode;
  variant: 'center' | 'left';
};

function ContentColumn({ children, variant }: ContentColumnProps) {
  const variantStyles = {
    center: {
      justifyContent: 'center',
    },
    left: {
      justifyContent: 'flex-start',
    },
  };

  return (
    <Stack
      sx={{
        width: '100%',
        height: '100%',
        padding: '16px 32px',
        alignItems: 'center',
      }}
    >
      <Stack
        sx={{
          width: '100%',
          height: '100%',
          maxWidth: '500px',
          gap: '16px',
          ...variantStyles[variant],
        }}
      >
        {children}
      </Stack>
    </Stack>
  );
}

export default ContentColumn;
