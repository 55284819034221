import PropertyPlaceholderImg from '@/public/house_placeholder.svg';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

type CardLineProps = {
  children: React.ReactNode;
};

type CardLineMediaProps = {
  src: string;
  alt?: string;
};

type CardLineTitleProps = {
  children: React.ReactNode;
};

type CardLineTextProps = {
  children: React.ReactNode;
};
function CardLine({ children }: CardLineProps) {
  return <Card variant="line">{children}</Card>;
}

CardLine.Media = function CardLineMedia({ src, alt }: CardLineMediaProps) {
  return (
    <CardMedia
      src={src}
      component="img"
      alt={alt}
      onError={(e: any) => {
        e.target.src = PropertyPlaceholderImg.src;
      }}
    />
  );
};

CardLine.Content = function CardLineContent({ children }: CardLineProps) {
  return (
    <CardContent>
      <Stack gap={0.5}>{children} </Stack>
    </CardContent>
  );
};

CardLine.Title = function CardLineTitle({ children }: CardLineTitleProps) {
  return <Typography variant="h6">{children}</Typography>;
};

CardLine.Text = function CardLineText({ children }: CardLineTextProps) {
  return <Typography variant="body2">{children}</Typography>;
};

export default CardLine;
