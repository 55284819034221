'use client';

import { Form } from '@/components/_common/Form/Form';
import {
  DynamicFooterComponent,
  DynamicFormComponent,
} from '@/components/_common/Form/FormTypes';
import { useTask } from '@/context/TaskContextProvider';

type Props = {
  transactionType: 'sale' | 'purchase';
};

function TransactionParticipantDoB({ transactionType }: Props) {
  const {
    state: { formValues },
    dispatch,
  } = useTask();

  const handleSubmit = (formData: any) => {
    if (transactionType === 'sale') {
      dispatch({ type: 'NEXT', payload: formData });
    } else {
      dispatch({ type: 'SET_FORM_VALUES', payload: formData });
      dispatch({ type: 'SET_ACTIVE_STEP', payload: 4 });
    }
  };

  const components: DynamicFormComponent[] = [
    {
      componentId: 'name',
      componentLabel: `What’s your date of birth?`,
      componentGuidance: null,
      componentInputs: [
        {
          name: 'dateOfBirth',
          type: 'date',
          label: 'Date of Birth',
          default_value: '',
          placeholder: 'dd/mm/yyyy',
          config: {
            required: { value: true, message: 'Required' },
          },
          submitFormat: 'DD/MM/YYYY',
        },
      ],
    },
  ];

  const footer: DynamicFooterComponent[] = [
    {
      componentId: 'backSubmit',
      componentInputs: [
        {
          name: 'backSubmit',
          type: 'backSubmit',
          label: 'Next',
          rank: 'primary',
          iconRight: 'chevron_right',
        },
      ],
    },
  ];

  return (
    <>
      <Form
        components={components}
        footerComponents={footer}
        formValues={formValues}
        onSubmit={(formData) => handleSubmit(formData)}
        onBack={() => dispatch({ type: 'BACK' })}
      />
    </>
  );
}

export default TransactionParticipantDoB;
