import CardsInfo from '@/components/_common/CardsInfo/CardsInfo';
import UserPlaceholderImg from '@/public/user-placeholder.png';
import { reverseArray } from '@/utils/helpers';
import Check from '@mui/icons-material/Check';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ls from 'localstorage-slim';

type Props = {
  participants: any;
};

function BiometricIDParticipants({ participants }: Props) {
  const isComplete = participants.every(
    (participant: any) => participant.verified
  );

  const generateChip = (participant: any) => {
    const isVerifyIdRequested =
      ls.get(`verify-id-requested-${participant.customer.customerId}`) || null;
    const userAwaitingVerifyId =
      isVerifyIdRequested && participant.verified !== 'verified';

    if (participant.verified)
      return <Chip label="Passed" size="small" icon={<Check />} />;
    if (userAwaitingVerifyId)
      return (
        <Chip label="Checking..." size="small" icon={<HourglassBottomIcon />} />
      );
    return (
      <Chip label="Not started" size="small" icon={<HourglassBottomIcon />} />
    );
  };

  return (
    <CardsInfo isComplete={isComplete}>
      {reverseArray(participants).map((participant: any) => (
        <Grid item lg={2} md={3} xs={12} key={participant.id}>
          <Card variant="info" elevation={participant.verified ? 1 : 0}>
            <CardHeader
              avatar={
                <CardMedia
                  component="img"
                  image={UserPlaceholderImg.src}
                ></CardMedia>
              }
            />
            <CardContent>
              <Typography variant="h6">ID checks</Typography>
              <Typography variant="body2">{`${participant.firstName} ${
                participant.lastName
              } ${participant.currentUser ? '(me)' : ''}`}</Typography>
              {generateChip(participant)}
            </CardContent>
          </Card>
        </Grid>
      ))}
    </CardsInfo>
  );
}

export default BiometricIDParticipants;
