'use client';

import DomainImage from '@/components/_common/DomainImage/DomainImage';
import PoweredByKotini from '@/components/_common/PoweredByKotini/PoweredByKotini';
import Company from '@/public/company-placeholder-2.png';
import { alpha, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createContext, useContext } from 'react';

type LandingProps = {
  children?: React.ReactNode;
  backgroundColor: string;
  contrastColor: string;
};

type LandingBrandingProps = {
  children: React.ReactNode;
  poweredBy: boolean;
};

type LandingContentProps = {
  children: React.ReactNode;
};

type LandingImageProps = {
  src: string;
  title?: string;
};

type LandingTitleProps = {
  text: string;
};

const LandingContext = createContext<LandingProps | undefined>(undefined);

export const LandingProvider: React.FC<LandingProps> = ({
  children,
  backgroundColor,
  contrastColor,
}) => {
  return (
    <LandingContext.Provider
      value={{
        backgroundColor,
        contrastColor,
      }}
    >
      {children}
    </LandingContext.Provider>
  );
};

const useLandingContext = () => {
  const context = useContext(LandingContext);
  if (!context) {
    throw new Error('useLandingContext must be used within a LandingProvider');
  }
  return context;
};

function Landing({ children, backgroundColor, contrastColor }: LandingProps) {
  const isXs = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  return (
    <LandingProvider
      backgroundColor={backgroundColor}
      contrastColor={contrastColor}
    >
      <Box
        sx={{
          display: 'flex',
          padding: isXs ? '16px' : '64px',
          alignItems: 'center',
          gap: isXs ? '16px' : '64px',
          width: '100%',
          height: ['100svh', '100vh'],
          backgroundColor: backgroundColor,
          flexDirection: isXs ? 'column' : 'row',
        }}
      >
        {children}
      </Box>
    </LandingProvider>
  );
}

Landing.Branding = function LandingBranding({
  children,
  poweredBy = true,
}: LandingBrandingProps) {
  const { contrastColor } = useLandingContext();
  const isXs = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));

  return (
    <Stack
      sx={{
        flex: isXs ? '0 0 0' : '1 0 0',
        gap: isXs ? 1 : 4,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {children}
      {poweredBy && <PoweredByKotini color={alpha(contrastColor, 0.6)} />}
    </Stack>
  );
};

Landing.Content = function LandingContent({ children }: LandingContentProps) {
  return (
    <Stack
      sx={{
        flexGrow: 1,
        flexBasis: 0,
        height: '100%',
      }}
    >
      {children}
    </Stack>
  );
};

Landing.Image = function LandingImage({ src, title }: LandingImageProps) {
  const { contrastColor } = useLandingContext();
  const isXs = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        border: 'solid',
        borderWidth: '4px',
        borderColor: alpha(contrastColor, 0.1),
        borderRadius: isXs ? 1.5 : 3.5,
      }}
    >
      <DomainImage
        src={src}
        alt={title || 'kotini'}
        size={isXs ? 'xl' : 'xxxl'}
        placeholderImageUrl={Company.src}
      />
    </Box>
  );
};

Landing.Title = function LandingTitle({ text }: LandingTitleProps) {
  const { contrastColor } = useLandingContext();
  const isXs = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));

  return (
    <Typography
      variant={isXs ? 'h5' : 'h2'}
      textAlign={'center'}
      sx={{ color: contrastColor }}
    >
      {text}
    </Typography>
  );
};

export default Landing;
