'use client';

import { useAuth } from '@/context/AuthContextProvider';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

/**
 * Component: Welcome Header
 */
export default function WelcomeHeader() {
  const { givenName } = useAuth();

  return (
    <Box display="flex" px={2}>
      {givenName ? (
        <Typography
          data-testid="welcome-header"
          variant="h3"
          sx={{ paddingBottom: 1 }}
        >{`Hi, ${givenName}!`}</Typography>
      ) : (
        <Skeleton
          animation="wave"
          sx={{
            width: 300,
            display: 'flex',
            gap: 2,
            height: '80px',
          }}
        />
      )}
    </Box>
  );
}
