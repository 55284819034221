'use client';

import Splash from '@/components/_common/Splash/Splash';
import { useTask } from '@/context/TaskContextProvider';
import BasketBg from '@/public/basket.svg';
import { START_CHECKOUT_SESSION } from '@/services/mutations';
import { useMutation } from '@apollo/client';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Refresh from '@mui/icons-material/Refresh';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Link from 'next/link';

type Props = {
  transactionId: string;
  referenceId: string;
  quantity: number;
  priceId: string;
  priceString: string;
  onComplete?: (data?: any) => void;
};

function TransactionPaymentOnboarding({
  transactionId,
  referenceId,
  quantity,
  priceId,
  priceString,
  onComplete,
}: Props) {
  const {
    state: { isComplete, isSubmitting, formValues },
    dispatch,
  } = useTask();

  const [startCheckoutSession] = useMutation(START_CHECKOUT_SESSION, {
    refetchQueries: ['transaction'],
    awaitRefetchQueries: true,
  });

  const handleMutation = async () => {
    const input = {
      referenceId: referenceId,
      quantity: quantity,
      priceId: priceId,
    };

    dispatch({ type: 'SET_IS_SUBMITTING', payload: true });

    startCheckoutSession({
      variables: { input: input },
      onCompleted: async (data: any) => {
        dispatch({ type: 'SET_IS_SUBMITTING', payload: false });
        dispatch({ type: 'SET_IS_COMPLETE', payload: true });
        dispatch({
          type: 'SET_FORM_VALUES',
          payload: { link: data.startCheckoutSession.link },
        });
        openPaymentLink(data.startCheckoutSession.link);
      },
    });
  };

  const openPaymentLink = (link: string) => {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = link;
    a.target = '_blank';
    a.click();
    document.body.removeChild(a);
  };

  // if (isSubmitting)
  //   return (
  //     <Splash
  //       title="Starting checkout session..."
  //       footer={null}
  //       isLoading={isSubmitting}
  //     />
  //   );

  return (
    <Splash
      title={`Get ready to pay your ${priceString} admin fee`}
      subtitle="Make sure you have a payment card to hand. If you think you've already done this, hit refresh or contact us using the help."
      backgroundImage={BasketBg}
      footer={
        <Stack gap={1} sx={{ flexDirection: 'row' }}>
          {isComplete ? (
            <Stack gap={1} sx={{ flexDirection: 'row' }}>
              <Button
                component={Link}
                color="secondary"
                size="large"
                href={formValues.link || '#'}
                target="_blank"
                endIcon={<Refresh />}
                data-testid="retry-payment"
              >
                Re-try payment
              </Button>
              <Button
                variant={'contained'}
                disabled={isSubmitting}
                size="large"
                endIcon={<ChevronRight />}
                sx={{ width: '100%', flex: 1 }}
                onClick={onComplete}
              >
                I&#39;ve paid
              </Button>
            </Stack>
          ) : (
            <Button
              variant={'contained'}
              disabled={isSubmitting}
              size="large"
              endIcon={<ChevronRight />}
              sx={{ width: '100%', flex: 1 }}
              onClick={handleMutation}
            >
              Make payment
            </Button>
          )}
        </Stack>
      }
      isLoading={isSubmitting}
      isSuccess={false}
    />
  );
}

export default TransactionPaymentOnboarding;
