'use client';

import Splash from '@/components/_common/Splash/Splash';
import { useAuth } from '@/context/AuthContextProvider';
import { useTask } from '@/context/TaskContextProvider';
import VeifyIdBg from '@/public/verify-id-bg.svg';
import { COMPLETE_TASK, START_VERIFY_ID } from '@/services/mutations';
import { GET_TRANSACTION_BIOMETRIC_ID } from '@/services/queries';
import { wait } from '@/utils/helpers';
import { useMutation, useQuery } from '@apollo/client';
import Check from '@mui/icons-material/Check';
import LockIcon from '@mui/icons-material/Lock';
import Refresh from '@mui/icons-material/Refresh';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import ls from 'localstorage-slim';
import { useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';
import { isBrowser } from 'react-device-detect';
import BiometricIDParticipants from './components/Participants';
import Tips from './components/Tips';
import VerifyIdDesktop from './components/VerifyIdDesktop';
import VerifyIdMobile from './components/VerifyIdMobile';

type Props = {
  transactionId: string;
  task?: any;
  onComplete?: (data?: any) => void;
};

function TransactionBiometricID({ transactionId, task, onComplete }: Props) {
  const router = useRouter();
  const { userId } = useAuth();
  // const {
  //   state: { currentTask },
  // } = useTaskGroup();
  const {
    state: { activeStep, isComplete, isSubmitting, formValues, waitDuration },
    dispatch,
  } = useTask();
  const [currentParticipant, setCurrentParticipant] = useState<any>(null);
  const [isVerifyIdRequested, setIsVerifyIdRequested] = useState<any>(false);
  const [participantsIDComplete, setParticipantsIDComplete] =
    useState<boolean>(false);

  const { data, loading, error, refetch } = useQuery(
    GET_TRANSACTION_BIOMETRIC_ID,
    {
      variables: { transactionId: transactionId },
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
    }
  );

  const [startVerifyId] = useMutation(START_VERIFY_ID, {
    refetchQueries: ['transaction'],
    awaitRefetchQueries: true,
  });

  const [completeTask] = useMutation(COMPLETE_TASK, {
    refetchQueries: ['transaction'],
    awaitRefetchQueries: true,
  });

  const handleMutation = async () => {
    const successCallbackUrl = `/`;
    const failureCallbackUrl = `/`;

    const input = {
      customerId: userId,
      successCallbackUrl: `${process.env.NEXT_PUBLIC_DOMAIN}${successCallbackUrl}`,
      failureCallbackUrl: `${process.env.NEXT_PUBLIC_DOMAIN}${failureCallbackUrl}`,
      mockVerify:
        process.env.NEXT_PUBLIC_APPLICATION_STAGE !== 'production'
          ? true
          : false,
    };

    dispatch({ type: 'SET_IS_SUBMITTING', payload: true });

    startVerifyId({
      variables: { input: input },
      onCompleted: async (data: any) => {
        ls.set(`verify-id-requested-${userId}`, true, {
          ttl: 240,
        });
        await wait(waitDuration);
        refetch();
        router.push(data?.startVerifyId?.link);
        // onComplete && onComplete();
        // dispatch({ type: 'SET_IS_SUBMITTING', payload: false });
        // dispatch({ type: 'SET_IS_COMPLETE', payload: true });
      },
    });
  };

  const handleCompleteTask = async () => {
    dispatch({ type: 'SET_IS_SUBMITTING', payload: true });
    completeTask({
      variables: { input: { taskId: task.taskId } },
      onCompleted: async () => {
        await wait(waitDuration);
        onComplete && onComplete();
        dispatch({ type: 'SET_IS_SUBMITTING', payload: false });
      },
    });
  };

  useEffect(() => {
    if (data) {
      const currentParticipant = data.transaction.participants.find(
        (participant: any) => participant.currentUser
      );

      setCurrentParticipant(currentParticipant);
      setIsVerifyIdRequested(
        ls.get(
          `verify-id-requested-${currentParticipant.customer.customerId}`
        ) || null
      );
      setParticipantsIDComplete(
        data.transaction.participants.every(
          (participant: any) => participant.verified
        )
      );
      dispatch({ type: 'SET_TOTAL_STEPS', payload: 4 });
      dispatch({ type: 'SET_ACTIVE_STEP', payload: 1 });
    }
  }, [data, dispatch]);

  if (loading)
    return <Splash title="Loading..." footer={null} isLoading={true} />;

  if (isSubmitting)
    return (
      <Splash
        title="Setting up your ID checks..."
        footer={
          <Button
            variant={'contained'}
            disabled={isSubmitting}
            size="large"
            endIcon={isSubmitting ? <LockIcon /> : null}
            sx={{ width: '100%' }}
            onClick={onComplete}
          >
            Next
          </Button>
        }
        isLoading={isSubmitting}
      />
    );

  if (
    isComplete ||
    (currentParticipant &&
      (currentParticipant?.verified || isVerifyIdRequested))
  )
    return (
      <Splash
        title={
          participantsIDComplete
            ? 'Everyone’s passed their ID checks!'
            : isVerifyIdRequested
              ? 'We’re checking your ID...'
              : 'You’ve passed your ID checks!'
        }
        subtitle={
          participantsIDComplete
            ? 'This task is complete, there’s nothing left for you to do here.'
            : isVerifyIdRequested
              ? 'We’ll let you know as soon as we’re done.'
              : 'Once everyone else has passed their ID checks we’ll mark this task as complete.'
        }
        content={
          <BiometricIDParticipants
            participants={data.transaction.participants}
          />
        }
        backgroundImage={VeifyIdBg}
        footer={
          <Stack gap={1} direction="row">
            <IconButton
              color="secondary"
              size="large"
              sx={{ flex: 0 }}
              onClick={() => refetch()}
              data-testid="refresh"
            >
              <Refresh />
            </IconButton>
            {participantsIDComplete && task.status !== 'complete' ? (
              <Button
                variant={'contained'}
                disabled={isSubmitting}
                size="large"
                endIcon={isSubmitting ? <LockIcon /> : <Check />}
                sx={{ width: '100%', flex: 1 }}
                onClick={handleCompleteTask}
              >
                Mark complete
              </Button>
            ) : (
              <Button
                variant={'contained'}
                disabled={isSubmitting}
                size="large"
                endIcon={isSubmitting ? <LockIcon /> : null}
                sx={{ width: '100%', flex: 1 }}
                onClick={onComplete}
              >
                Next
              </Button>
            )}
          </Stack>
        }
        isLoading={isSubmitting}
        isSuccess={participantsIDComplete}
      />
    );

  if (data && currentParticipant)
    return (
      <>
        {activeStep === 1 &&
          (isBrowser ? (
            <Tips
              name={`${currentParticipant.firstName} ${currentParticipant.lastName}`}
              onComplete={onComplete}
            />
          ) : (
            <VerifyIdMobile
              name={`${currentParticipant.firstName} ${currentParticipant.lastName}`}
              onComplete={onComplete}
              onSubmit={handleMutation}
            />
          ))}
        {activeStep === 2 && (
          <VerifyIdDesktop onComplete={onComplete} refetch={refetch} />
        )}
      </>
    );
}

export default TransactionBiometricID;
